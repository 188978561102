import React from 'react'
import { Field, ErrorMessage } from 'formik';
const AgentTab = () => {
    
    return (
        <>
           <div className="mt-5 d-flex justify-content-center align-items-center">
        <h2 className="progressbar-below-form">Agent Details</h2>
      </div>
      <div className="Form_main_wrap_for_details">

            <form className='mt-4' >
                <div className="row mb-4">
                <div className="form-group col-sm-6">
                    <label className="d-block">Full Name</label>
                    <Field type="text" className="player-body-input p-4" name="Name"  />
                        <ErrorMessage name="Name" component="div" className="error-message text-danger" />
                </div>
                <div className="form-group col-sm-6">
                    <label className="d-block">Mobile No.</label>
                    <Field type="text" className="player-body-input p-4" name="number"  />
                        <ErrorMessage name="number" component="div" className="error-message text-danger" />
                </div>
                </div>
                <div class="mb-4">
                    <label for="exampleFormControlTextarea1" class="form-label">Little about you</label>
                    <Field as="textarea" className="form-control player-body-input-1" id="exampleFormControlTextarea1" name="about" rows="3" />
                        <ErrorMessage name="about" component="div" className="error-message text-danger" />
                </div>

                <div className='row mb-4'>
                    <div className="form-group col-sm-6">
                        <label className="d-block">Location</label>
                        <Field type="text" className="player-body-input p-4" name="location" />
                            <ErrorMessage name="location" component="div" className="error-message text-danger" />
                    </div>
                    <div className="form-group  col-sm-6">
                        <label className="d-block">Date of Birth</label>
                        <div className="d-flex">
                                <Field type="text" className="player-body-input-2 mr-2" name="dateOfBirth.day" placeholder="Day" />
                                <Field type="text" className="player-body-input-2 mx-2" name="dateOfBirth.month" placeholder="Month" />
                                <Field type="text" className="player-body-input-2 ml-2" name="dateOfBirth.year" placeholder="Year" />
                            </div>
                            <ErrorMessage name="dateOfBirth" component="div" className="error-message text-danger " />
                            <ErrorMessage name="month" component="div" className="error-message text-danger" />
                            <ErrorMessage name="year" component="div" className="error-message text-danger" />
                    </div>
                </div>
                <div className="row">
            <div className="col-sm-6">
            <div className="form-group ">
                           <label className="d-block">CPF ID</label>
                           <Field type="text" className="player-body-input p-4" name="cpf" />
                        <ErrorMessage name="cpf" component="div" className="error-message text-danger" />
                       </div>
            </div>
            </div>


            </form>
            </div>

        </>
    )
}

export default AgentTab