

import React, { useState } from 'react';
import { ReactMultiEmail } from 'react-multi-email';
import 'react-multi-email/dist/style.css';
import { useNavigate } from 'react-router-dom';
import user from '../../../../assets/Ellipse 7.png'
const AddTeam = () => {
  const [emails, setEmails] = useState([]);
  const [focused, setFocused] = useState(false);

  // const navigate = useNavigate();

  // const handleNextClick = () => {
  //   navigate('#'); 
  // };

  // const handleBackClick = () => {
  //   navigate(-1);
  // };

  return (
    <div className=''>
     
    <div className="mt-5 d-flex justify-content-center align-items-center">
       <h2 className="progressbar-below-form">Add Team</h2>
     </div>
         <form>
           <div className="Add_team_email">
             <label htmlFor="Email">Email</label>
             <div className="Add_team_email_btn d-flex align-items-center gap-24">
             <input type="text" />
<button>Invite</button>
             </div>
           </div>
           {/* <ReactMultiEmail
             placeholder='Input your email'
             emails={emails}
             onChange={(updatedEmails) => setEmails(updatedEmails)}
             autoFocus={true}
             onFocus={() => setFocused(true)}
             onBlur={() => setFocused(false)}
             className='multy-email-input'
             getLabel={(email, index, removeEmail) => (
               <div data-tag key={index}>
                 <div data-tag-item>{email}</div>
                 <span data-tag-handle onClick={() => removeEmail(index)}>
                   ×
                 </span>
               </div>
             )}
           /> */}
         
           <div className='members_main_wrap'>
               <h2 className='member-text'>Members</h2>
               <div className='d-flex gap-21'>
                   <div>
                       <img src={user} alt="" />
                   </div>
                   <div className='user_name_email_wrap'>
                       <div className='member-name d-flex align-items-center'><p>Jenna Doe</p> <button className='you-button'>You</button></div>
                       <span>j.doe@example.com</span>

                   </div>
               </div>
               <div className='link-div'>
                   <div>
                   <p>Copy Link</p>
                   <div className='d-flex gap-4'>
                   <div className='link-div-input'><input type="text" placeholder='prospects.so/all-task-03cF54Dace1254967435' /></div>
<button className='invite-button'>Invite</button>

                   </div>
                   </div>
               </div>
           </div>
         </form>
        
   </div>
  );
}

export default AddTeam;



