// import React from 'react'

// const ProfileViewChart = () => {
//   return (
//     <div>ProfileViewChart</div>
//   )
// }

// export default ProfileViewChart





import React from 'react';
import Chart from 'react-apexcharts';

const ProfileViewChart = ({ match, reels }) => {
  const options = {
    chart: {
      width: 280,
      type: 'donut',
    },
    plotOptions: {
      pie: {
        startAngle: 190,
        endAngle: -90,
      },
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'gradient',
    },
    labels: ['Match', 'Reels'],
    legend: {
      show: false,
    },
    colors: ['#FAC364', '#A6DFFF'], // Match and Reels colors
    responsive: [{
      breakpoint: 280,
      options: {
        chart: {
          width: 100,
        },
        legend: {
          position: 'bottom',
        },
      },
    }],
  };

  const series = [match, reels];

  return (
    <div className="home_card">
      <div className="total_content">
        <h6 className="main_head">Profile View</h6>
        <div className="total_content_chart text-center">
          <Chart options={options} series={series} type="donut" height={180} />
        </div>
        <div className="match_reels_wrap">
          <div className="d-flex justify-content-between align-items-center mb-20px chart_reading_info">
            <h6>
              <span className="home_chart_color_btn1" style={{backgroundColor: '#A6DFFF'}}></span> Technical Profile
            </h6>
            <p>{match}</p>
          </div>
          <div className="d-flex justify-content-between align-items-center mb-20px chart_reading_info">
            <h6>
              <span className="home_chart_color_btn" style={{backgroundColor: '#FAC364' }}></span> Personal Profile
            </h6>
            <p>{reels}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileViewChart;