// import React, { useState } from "react";

// const positionOptions = [
//   "Goalkeeper",
//   "Right Wingback",
//   "Left Wingback",
//   "Center Back",
//   "Sweeper",
//   "Defending",
//   "Right Midfielder",
//   "Center Midfielder",
//   "Center Striker",
//   "Attacking Midfielder",
//   "Left Midfielder",
// ];

// const SecTab = () => {
//   const [experiences, setExperiences] = useState([
//     {
//       playingTeam: "",
//       achievements: "",
//       startDate: { month: "", year: "" },
//       endDate: { month: "", year: "" },
//       position: "",
//       tshirtNumber: "",
//     },
//   ]);

//   const handleInputChange = (index, field, value) => {
//     const newExperiences = [...experiences];
//     const fieldPath = field.split(".");

//     if (fieldPath.length > 1) {
//       newExperiences[index][fieldPath[0]][fieldPath[1]] = value;
//     } else {
//       newExperiences[index][field] = value;
//     }
//     setExperiences(newExperiences);
//   };

//   const handleMonthInput = (index, field, value) => {
//     const cleanValue = value.replace(/\D/g, "");
//     if (cleanValue === "") {
//         handleInputChange(index, field, "");
//       } else {
//         const intValue = parseInt(cleanValue, 10);
    
//         if (intValue >= 1 && intValue <= 12) {
//           handleInputChange(index, field, intValue.toString().padStart(2, '0'));
//         } else {
//           handleInputChange(index, field, cleanValue);
//         }
//       }
    
//   };
  
  

//   const handleYearInput = (index, field, value) => {
//     const cleanValue = value.replace(/\D/g, "");
//     if (cleanValue.length <= 4) {
//       handleInputChange(index, field, cleanValue);
//     }
//   };

//   const addExperience = () => {
//     setExperiences([
//       ...experiences,
//       {
//         playingTeam: "",
//         achievements: "",
//         startDate: { month: "", year: "" },
//         endDate: { month: "", year: "" },
//         position: "",
//         tshirtNumber: "",
//       },
//     ]);
//   };

//   const removeExperience = (index) => {
//     const newExperiences = experiences.filter((_, i) => i !== index);
//     setExperiences(newExperiences);
//   };

//   return (
//     <>
//       <div className="mt-5 d-flex justify-content-center align-items-center">
//         <h2 className="progressbar-below-form">Experience</h2>
//       </div>
//       <div className="Form_main_wrap_for_details mb-5">
//         {experiences.map((experience, index) => (
//           <div key={index} className="mb-4">
//             <div className="row">
//               <div className="col-sm-6">
//                 <div className="form-group">
//                   <label className="d-block">Playing Team</label>
//                   <input
//                     type="text"
//                     value={experience.playingTeam}
//                     onChange={(e) =>
//                       handleInputChange(index, "playingTeam", e.target.value)
//                     }
//                     className="player-body-input p-4"
//                     placeholder=""
//                   />
//                 </div>
//               </div>
//             </div>
//             <div className="row">
//               <div className="col-sm-12">
//                 <label className="form-label">
//                   Give us an overview of your Achievements
//                 </label>
//                 <textarea
//                   className="form-control player-body-input-1"
//                   value={experience.achievements}
//                   onChange={(e) =>
//                     handleInputChange(index, "achievements", e.target.value)
//                   }
//                   rows="3"
//                   style={{ backgroundColor: "#171919", color: "white" }}
//                 />
//               </div>
//             </div>
//             <div className="row">
//               <div className="col-sm-6">
//                 <div className="form-group">
//                   <label className="d-block">Start Date</label>
//                   <div className="d-flex">
//                     <input
//                       type="text"
//                       value={experience.startDate.month}
//                       onChange={(e) =>
//                         handleMonthInput(index, "startDate.month", e.target.value)
//                       }
//                       className="player-body-input-3 mr-2"
//                       placeholder="Month"
//                     />
//                     <input
//                       type="text"
//                       value={experience.startDate.year}
//                       onChange={(e) =>
//                         handleYearInput(index, "startDate.year", e.target.value)
//                       }
//                       className="player-body-input-3 mx-2"
//                       placeholder="Year"
//                     />
//                   </div>
//                 </div>
//               </div>
//               <div className="col-sm-6">
//                 <div className="form-group">
//                   <label className="d-block">End Date</label>
//                   <div className="d-flex">
//                     <input
//                       type="text"
//                       value={experience.endDate.month}
//                       onChange={(e) =>
//                         handleMonthInput(index, "endDate.month", e.target.value)
//                       }
//                       className="player-body-input-3 mr-2"
//                       placeholder="Month"
//                     />
//                     <input
//                       type="text"
//                       value={experience.endDate.year}
//                       onChange={(e) =>
//                         handleYearInput(index, "endDate.year", e.target.value)
//                       }
//                       className="player-body-input-3 mx-2"
//                       placeholder="Year"
//                     />
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div className="row">
//               <div className="col-sm-6">
//                 <div className="form-group">
//                   <label className="d-block">Position</label>
//                   <select
//                     value={experience.position}
//                     onChange={(e) =>
//                       handleInputChange(index, "position", e.target.value)
//                     }
//                     className="player-body-input"
//                     style={{
//                       padding: "10px",
//                       height: "50px",
//                     }}
//                   >
//                     <option value="" label="Select position" />
//                     {positionOptions.map((position, posIndex) => (
//                       <option key={posIndex} value={position}>
//                         {position}
//                       </option>
//                     ))}
//                   </select>
//                 </div>
//               </div>
//               <div className="col-sm-6">
//                 <div className="form-group">
//                   <label className="d-block">T-Shirt No.</label>
//                   <input
//                     type="text"
//                     value={experience.tshirtNumber}
//                     onChange={(e) =>
//                       handleInputChange(index, "tshirtNumber", e.target.value)
//                     }
//                     className="player-body-input p-4"
//                     placeholder=""
//                   />
//                 </div>
//               </div>
//             </div>
//             {index > 0 && (
//               <div className="experience-remove mt-4 text-danger">
//                 <p onClick={() => removeExperience(index)}>Remove Experience</p>
//               </div>
//             )}
//           </div>
//         ))}
//         <div className="experience-add mt-4">
//           <p onClick={addExperience}>+ Add More</p>
//         </div>
//       </div>
//     </>
//   );
// };

// export default SecTab;





import React, { useState } from "react";

const positionOptions = [
  "Goalkeeper",
  "Right Wingback",
  "Left Wingback",
  "Center Back",
  "Sweeper",
  "Defending",
  "Right Midfielder",
  "Center Midfielder",
  "Center Striker",
  "Attacking Midfielder",
  "Left Midfielder",
];

const SecTab = ({ setFieldValue }) => {
  const [experiences, setExperiences] = useState([
    {
      playingTeam: "",
      achievements: "",
      startDate: { month: "", year: "" },
      endDate: { month: "", year: "" },
      position: "",
      tshirtNumber: "",
    },
  ]);

  const handleInputChange = (index, field, value) => {
    const newExperiences = [...experiences];
    const fieldPath = field.split(".");

    if (fieldPath.length > 1) {
      newExperiences[index][fieldPath[0]][fieldPath[1]] = value;
    } else {
      newExperiences[index][field] = value;
    }
    setExperiences(newExperiences);
    setFieldValue(`experience[${index}].${field}`, value); // Update Formik field value
  };

  const handleMonthInput = (index, field, value) => {
    const cleanValue = value.replace(/\D/g, "");
    if (cleanValue === "") {
      handleInputChange(index, field, "");
    } else {
      const intValue = parseInt(cleanValue, 10);
      if (intValue >= 1 && intValue <= 12) {
        handleInputChange(index, field, intValue.toString().padStart(2, '0'));
      } else {
        handleInputChange(index, field, cleanValue);
      }
    }
  };

  const handleYearInput = (index, field, value) => {
    const cleanValue = value.replace(/\D/g, "");
    if (cleanValue.length <= 4) {
      handleInputChange(index, field, cleanValue);
    }
  };

  const addExperience = () => {
    const newExperience = {
      playingTeam: "",
      achievements: "",
      startDate: { month: "", year: "" },
      endDate: { month: "", year: "" },
      position: "",
      tshirtNumber: "",
    };
    setExperiences([...experiences, newExperience]);
    setFieldValue(`experience[${experiences.length}]`, newExperience); // Add new entry to Formik
  };

  const removeExperience = (index) => {
    const newExperiences = experiences.filter((_, i) => i !== index);
    setExperiences(newExperiences);
    setFieldValue("experience", newExperiences); // Update Formik field value
  };

  return (
    <>
      <div className="mt-5 d-flex justify-content-center align-items-center">
        <h2 className="progressbar-below-form">Experience</h2>
      </div>
      <div className="Form_main_wrap_for_details mb-5">
        {experiences.map((experience, index) => (
          <div key={index} className="mb-4">
            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <label className="d-block">Playing Team</label>
                  <input
                    type="text"
                    value={experience.playingTeam}
                    onChange={(e) =>
                      handleInputChange(index, "playingTeam", e.target.value)
                    }
                    className="player-body-input p-4"
                    placeholder=""
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <label className="form-label">
                  Give us an overview of your Achievements
                </label>
                <textarea
                  className="form-control player-body-input-1"
                  value={experience.achievements}
                  onChange={(e) =>
                    handleInputChange(index, "achievements", e.target.value)
                  }
                  rows="3"
                  style={{ backgroundColor: "#171919", color: "white" }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <label className="d-block">Start Date</label>
                  <div className="d-flex">
                    <input
                      type="text"
                      value={experience.startDate.month}
                      onChange={(e) =>
                        handleMonthInput(index, "startDate.month", e.target.value)
                      }
                      className="player-body-input-3 mr-2"
                      placeholder="Month"
                    />
                    <input
                      type="text"
                      value={experience.startDate.year}
                      onChange={(e) =>
                        handleYearInput(index, "startDate.year", e.target.value)
                      }
                      className="player-body-input-3 mx-2"
                      placeholder="Year"
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label className="d-block">End Date</label>
                  <div className="d-flex">
                    <input
                      type="text"
                      value={experience.endDate.month}
                      onChange={(e) =>
                        handleMonthInput(index, "endDate.month", e.target.value)
                      }
                      className="player-body-input-3 mr-2"
                      placeholder="Month"
                    />
                    <input
                      type="text"
                      value={experience.endDate.year}
                      onChange={(e) =>
                        handleYearInput(index, "endDate.year", e.target.value)
                      }
                      className="player-body-input-3 mx-2"
                      placeholder="Year"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <label className="d-block">Position</label>
                  <select
                    value={experience.position}
                    onChange={(e) =>
                      handleInputChange(index, "position", e.target.value)
                    }
                    className="player-body-input"
                    style={{
                      padding: "10px",
                      height: "50px",
                    }}
                  >
                    <option value="" label="Select position" />
                    {positionOptions.map((position, posIndex) => (
                      <option key={posIndex} value={position}>
                        {position}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label className="d-block">T-Shirt No.</label>
                  <input
                    type="text"
                    value={experience.tshirtNumber}
                    onChange={(e) =>
                      handleInputChange(index, "tshirtNumber", e.target.value)
                    }
                    className="player-body-input p-4"
                    placeholder=""
                  />
                </div>
              </div>
            </div>
            {index > 0 && (
              <div className="experience-remove mt-4 text-danger">
                <p onClick={() => removeExperience(index)}>Remove Experience</p>
              </div>
            )}
          </div>
        ))}
        <div className="experience-add mt-4">
          <p onClick={addExperience}>+ Add More</p>
        </div>
      </div>
    </>
  );
};

export default SecTab;
