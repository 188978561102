    import { configureStore } from '@reduxjs/toolkit';
    import UserSlice from '../slice/user';
    import FormDataSlice from '../slice/formData';
    import Reel from '../slice/Reel';
    import AgentSlice from '../slice/Agent'
    import SchoolSlice from '../slice/school'
    import ClubSlice from '../slice/Club'
    import profileReducer from '../slice/GetPlayer';
    import profileImageReducer from'../slice/PlayerImageUpload'



    export const store = configureStore({
        reducer: {
            user: UserSlice,
            from:FormDataSlice,
            Agent: AgentSlice ,   
            school: SchoolSlice ,  
            club: ClubSlice ,  
            reels:Reel,
            profile:profileReducer,
            profileImage: profileImageReducer,
        },
    })

    