// import React, { useState } from "react";
// import { Formik, Form } from "formik";
// import * as yup from "yup";
// import SecTab from "./Tab/SecTab";
// import ClubTab from "./Tab/ClubTab";
// import SchoolTab from "./Tab/SchoolTab";
// import FirstTab from "./Tab/FirstTab";
// import UploadPictures from "./Tab/Uploadpictures";

// import { useDispatch } from "react-redux";
// import { useNavigate } from "react-router-dom";
// import { creatPlayerSlice } from "../../../redux/slice/formData";
// import { toast } from "react-toastify";
// import { updateUserSlice } from "../../../redux/slice/user";
// import { updateUserApi } from "../../../redux/api/user";

// const loginData = localStorage.getItem("loginData");

// let getuserData = {};
// if (loginData && loginData !== "undefined") {
//   try {
//     getuserData = JSON.parse(loginData);
//   } catch (error) {
//     console.error("Error parsing JSON:", error);
//   }
// }

// const userToken = getuserData.token;

// const userid = getuserData?._id;  


// console.log("userid", userid);
// console.log("userToken", userToken);

// const validationSchemas = {
//   1: yup.object().shape({
//     Name: yup.string().required("Full Name is required"),
//     gender: yup.string().required("Gender is required"),
//     currentHeight: yup.string().required("Height is required"),
//     currentWeight: yup.string().required("Weight is required"),
//     location: yup.string().required("Location is required"),
//   }),
//   2: yup.object().shape({
//     // playingTeam: yup.string().required("Playing Team is required"),
//   }),
//   3: yup.object().shape({}),
//   4: yup.object().shape({}),
// };

// const initialValues = {
//   // userId: "",
//   // agentId: "66a238c94ca8e9d805c6471c",
//   // currentClubId: "667e9dbead7c85c8b8804b69",
//   // currentSchoolId: "66a21fd64ca8e9d805c64659",
//   // Name: getuserData?.Name || "",
//   // displayName: "any",
//   // whatsapp: "any",
//   // cpf: "",
//   // number: getuserData?.number || "",
//   // playingTeam: "",
//   // currentHeight: "",
//   // currentWeight: "",
//   // birthDate: {
//   //   day: "",
//   //   month: "",
//   //   year: "",
//   // },
//   // position: "",
//   // playingSince: "",
//   // gender: "",
//   // about: "",
//   // location: "",
//   // experience: {
//   //   league: "any",
//   //   achievements: "",
//   //   startDate: "",
//   //   endDate: "",
//   //   tshirtNumber: "",
//   // },
//   userId: "",
//   agentId: "66a238c94ca8e9d805c6471c",
//   currentClubId: "667e9dbead7c85c8b8804b69",
//   currentSchoolId: "66a21fd64ca8e9d805c64659",
//   Name: getuserData?.Name || "",
//   cpf: "",
//   number: getuserData?.number || "",
//   // playingTeam: "",
//   currentHeight: "",
//   currentWeight: "",
//   birthDate: "",
//   playingSince: "",
//   gender: "",
//   about: "",
//   location: "",
//   experience: [
//     {
//       // "league": "string",
//       // "location": "string",
//       achievements: "",
//       startDate: "",
//       endDate: "",
//       position: "",
//       tshirtNumber: "",
//       playingTeam: "",
//     },
//     {
//       // "league": "string",
//       // "location": "string",
//       achievements: "",
//       startDate: "",
//       endDate: "",
//       position: "",
//       tshirtNumber: "",
//       playingTeam: "",
//     },
//   ],
// };

// const PlayerSignup = () => {
//   const [tab, setTab] = useState(1);
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const UserRole = localStorage.getItem("UserRole");
//   console.log("UserRole", UserRole);

//   const handleNextClick = async (formik) => {
//     try {
//       await validationSchemas[tab].validate(formik.values, {
//         abortEarly: false,
//       });
//       setTab(tab + 1);
//     } catch (err) {
//       console.error("Validation Error:", err);

//       if (err.inner && Array.isArray(err.inner)) {
//         const errors = err.inner.reduce((acc, curr) => {
//           acc[curr.path] = curr.message;
//           return acc;
//         }, {});
//         formik.setErrors(errors);
//         formik.setTouched(
//           Object.keys(errors).reduce((acc, curr) => {
//             acc[curr] = true;
//             return acc;
//           }, {})
//         );
//       } else {
//         toast.error("An unexpected validation error occurred.");
//       }
//       // toast.error("Please correct the errors before proceeding.");
//     }
//   };

//   // const formatDate = (day, month, year) => {
//   //   const formattedDay = day.padStart(2, "0");
//   //   const formattedMonth = month.padStart(2, "0");
//   //   return `${year}-${formattedMonth}-${formattedDay}`;
//   // };

//   const formatDate = (year, month, day) => {
//     const formattedDay = day.padStart(2, '0');
//     const formattedMonth = month.padStart(2, '0');
//     return `${year}-${formattedMonth}-${formattedDay}`;
// };
//   const onSubmit = async (values, { setSubmitting }) => {
//     try {
//       debugger
//       const { birthDate } = values;
//      const formattedBirthDate = formatDate(birthDate.year, birthDate.month, birthDate.day);
//         values.birthDate = formattedBirthDate;
//       const StartDate = `${
//         values.experience.startDate.year
//       }-${values.experience.startDate.month.padStart(2, "0")}`;
//       values.experience.startDate = StartDate;

//       const EndDate = `${
//         values.experience.endDate.year
//       }-${values.experience.endDate.month.padStart(2, "0")}`;
//       values.experience.endDate = EndDate;

//       const newVal = {
//         ...values,
//         userId: userid,
//         role: UserRole,
//         token: userToken,
//       };
//       console.log("newVal", newVal);
//       debugger

//       const createResponse = await dispatch(creatPlayerSlice(newVal));

//       console.log("createResponse", createResponse);
//       console.log("createResponse", createResponse.payload);

//       if (createResponse.payload.isSuccess === true) {
//         const userRoleResponse = await updateUserApi({
//           id: userid,
//           token: userToken,
//           data: { role: UserRole },
//         });
//         console.log("userRoleResponse", userRoleResponse);

//         if (userRoleResponse?.data?.role) {
//           localStorage.setItem("loginData", JSON.stringify(newVal));
//           navigate("/player");
//           toast.success("Player Registration successful!");
//         } else {
//           throw new Error("Failed to update user role");
//         }
//       } else {
//         throw new Error("Failed to create player");
//       }
//     } catch (error) {
//       //   console.error("Submission Error:", error);
//       toast.error("An error occurred during submission.");
//     } finally {
//       setSubmitting(false);
//     }
//   };

//   return (
//     <div className="container">
//       <div className="progressbar d-flex flex-column align-items-center">
//         <div className="text-center">
//           <span className="progressbar-header">You are almost there!</span>
//         </div>

//         <div className="align-items-center">
//           <div className="d-flex align-items-center">
//             <div className="progress">
//               <div
//                 className="progress-bar bg-success"
//                 style={{ width: `${(tab / 5) * 100}%` }}
//               ></div>
//             </div>
//             <span className="ml-4">{(tab / 5) * 100}%</span>
//           </div>

//           <Formik
//             initialValues={initialValues}
//             validationSchema={validationSchemas[tab]}
//             onSubmit={onSubmit}
//           >
//             {(formik) => (
//               <Form className="mt-4">
//                 {tab === 1 && <FirstTab setFieldValue={formik.setFieldValue} />}
//                 {tab === 2 && <SecTab setFieldValue={formik.setFieldValue} />}
//                 {tab === 3 && <ClubTab setFieldValue={formik.setFieldValue} />}
//                 {tab === 4 && (
//                   <SchoolTab setFieldValue={formik.setFieldValue} />
//                 )}
//                 {tab === 5 && (
//                   <UploadPictures setFieldValue={formik.setFieldValue} />
//                 )}

//                 {tab > 1 && (
//                   <div className="previouspage-arrow">
//                     <button
//                       type="button"
//                       className="nextpage-arrow-button"
//                       onClick={() => setTab(tab - 1)}
//                     >
//                       <i className="fa-solid fa-less-than"></i>
//                     </button>
//                   </div>
//                 )}
//                 {tab < 5 && (
//                   <div className="nextpage-arrow">
//                     <button
//                       type="button"
//                       className="nextpage-arrow-button"
//                       onClick={() => handleNextClick(formik)}
//                     >
//                       <i className="fa-solid fa-greater-than"></i>
//                     </button>
//                   </div>
//                 )}
//                 {/* <div>
//                                         {tab < 5 && (
//                                             <button
//                                                 type='button'
//                                                 className='next-button ml-4'
//                                                 onClick={() => handleNextClick(formik)}
//                                             >
//                                                 Next
//                                             </button>
//                                         )}
//                                         {tab === 5 && (
//                                             <button
//                                                 type='button'
//                                                 className='submit-button ml-4'
//                                                 onClick={formik.handleSubmit}
//                                             >
//                                                 Submit
//                                             </button>
//                                         )}
//                                     </div> */}
//                 <div className="d-flex justify-content-center align-items-center mt-4">
//                   <button type="button" className="skip-button">
//                     Skip
//                   </button>
//                   <button
//                     type="button"
//                     className="next-button ml-4"
//                     onClick={() =>
//                       tab < 5 ? handleNextClick(formik) : formik.handleSubmit()
//                     }
//                   >
//                     {tab < 5 ? "Next" : "Submit"}{" "}
//                   </button>
//                 </div>
//               </Form>
//             )}
//           </Formik>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default PlayerSignup;






import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as yup from "yup";
import SecTab from "./Tab/SecTab";
import ClubTab from "./Tab/ClubTab";
import SchoolTab from "./Tab/SchoolTab";
import FirstTab from "./Tab/FirstTab";
import UploadPictures from "./Tab/Uploadpictures";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { creatPlayerSlice } from "../../../redux/slice/formData";
import { toast } from "react-toastify";
import { createPlayerApi, updateUserApi } from "../../../redux/api/user";

const loginData = localStorage.getItem("loginData");

let getuserData = {};
if (loginData && loginData !== "undefined") {
  try {
    getuserData = JSON.parse(loginData);
  } catch (error) {
    console.error("Error parsing JSON:", error);
  }
}

// console.log('getuserDataaaaa',getuserData.user.number);


const userToken = getuserData?.token;
const userid = getuserData?.user?._id;



// console.log('User Token:', userToken);
// console.log('User ID:', userid);

const validationSchemas = {
  1: yup.object().shape({
    Name: yup.string().required("Full Name is required"),
    gender: yup.string().required("Gender is required"),
    currentHeight: yup.string().required("Height is required"),
    currentWeight: yup.string().required("Weight is required"),
    location: yup.string().required("Location is required"),
    birthDate: yup
      .object()
      .shape({
        year: yup.string().required("Year is required"),
        month: yup.string().required("Month is required"),
        day: yup.string().required("Day is required"),
      })
      .required("Birthdate is required"),
  }),
  2: yup.object().shape({}),
  3: yup.object().shape({}),
  4: yup.object().shape({}),
};

const initialValues = {
  
  currentClubId: "667e9dbead7c85c8b8804b69",
  agentId: "66a238c94ca8e9d805c6471c",
  currentSchoolId: "66a21fd64ca8e9d805c64659",
  Name: getuserData?.user?.Name || "",
  whatsapp: "",
  cpf: "",
  number: getuserData?.user?.number ||"",
  currentHeight: "",
  currentWeight: "",
  birthDate: "",
  position: "",
  playingSince: "",
  gender: "",
  about: "",
  location: "",
  experience: [
    {    
      achievements: "",
      startDate: "",
      endDate: "",
      position: "",
      tshirtNumber: "",
      playingTeam: ""
    }
  ]
};

const PlayerSignup = () => {
  const [tab, setTab] = useState(1);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const UserRole = localStorage.getItem("UserRole");

  const handleNextClick = async (formik) => {
    try {
      await validationSchemas[tab].validate(formik.values, {
        abortEarly: false,
      });
      setTab(tab + 1);
    } catch (err) {
      console.error("Validation Error:", err);
      if (err.inner && Array.isArray(err.inner)) {
        const errors = err.inner.reduce((acc, curr) => {
          acc[curr.path] = curr.message;
          return acc;
        }, {});
        formik.setErrors(errors);
        formik.setTouched(
          Object.keys(errors).reduce((acc, curr) => {
            acc[curr] = true;
            return acc;
          }, {})
        );
      } else {
        toast.error("An unexpected validation error occurred.");
      }
    }
  };

  const formatDate = (year, month, day) => {
    const formattedDay = day.padStart(2, "0");
    const formattedMonth = month.padStart(2, "0");
    return `${year}-${formattedMonth}-${formattedDay}`;
  };

  

  const onSubmit = async (values, { setSubmitting }) => {
    console.log('Values before formatting:', typeof(values.Name));
  
    debugger  
    try {    
  
      const { birthDate } = values;
      const formattedBirthDate = formatDate(birthDate.year, birthDate.month, birthDate.day);
      values.birthDate = formattedBirthDate;
  
      values.experience = values.experience.map((exp) => {
        const formattedStartDate = exp.startDate
          ? `${exp.startDate.year}-${exp.startDate.month.padStart(2, "0")}`
          : "";
        const formattedEndDate = exp.endDate
          ? `${exp.endDate.year}-${exp.endDate.month.padStart(2, "0")}`
          : "";
  
        return {
          league: exp.league || "",
          location: exp.location || "",
          startDate: formattedStartDate,
          endDate: formattedEndDate,
          position: exp.position || "",
          tshirtNumber: exp.tshirtNumber || "",
          playingTeam: exp.playingTeam || "",
          achievements: exp.achievements || "",
        };
      });
  
      const newVal = {
        ...values,
        userId: userid,
        role: UserRole,
        token: userToken,
      };
     
  
      const createResponse = await createPlayerApi(newVal);
      
  
      if (createResponse.isSuccess === true) {
        const userRoleResponse = await updateUserApi({
          id: userid,
          token: userToken,
          data: { role: UserRole },
        });
  
        if (userRoleResponse?.data?.role) {
          localStorage.setItem("loginData", JSON.stringify(newVal));
          navigate("/player");
          toast.success("Player Registration successful!");
        } else {
          throw new Error("Failed to update user role");
        }
      } else {
        throw new Error("Failed to create player");
      }
    } catch (error) {
      console.error("Error during submission:", error);
      toast.error("An error occurred during submission.");
    } finally {
      setSubmitting(false);
    }
  };
  
  


  return (
    <div className="container">
      <div className="progressbar d-flex flex-column align-items-center">
        <div className="text-center">
          <span className="progressbar-header">You are almost there!</span>
        </div>

        <div className="align-items-center">
          <div className="d-flex align-items-center">
            <div className="progress">
              <div
                className="progress-bar bg-success"
                style={{ width: `${(tab / 5) * 100}%` }}
              ></div>
            </div>
            <span className="ml-4">{(tab / 5) * 100}%</span>
          </div>

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchemas[tab]}
            onSubmit={onSubmit}
          >
            {(formik) => (
              <Form className="mt-4">
                {tab === 1 && <FirstTab setFieldValue={formik.setFieldValue} />}
                {tab === 2 && <SecTab setFieldValue={formik.setFieldValue} />}
                {tab === 3 && <ClubTab setFieldValue={formik.setFieldValue} />}
                {tab === 4 && <SchoolTab setFieldValue={formik.setFieldValue} />}
                {tab === 5 && <UploadPictures setFieldValue={formik.setFieldValue} />}

                {tab > 1 && (
                  <div className="previouspage-arrow">
                    <button
                      type="button"
                      className="nextpage-arrow-button"
                      onClick={() => setTab(tab - 1)}
                    >
                      <i className="fa-solid fa-less-than"></i>
                    </button>
                  </div>
                )}
                {tab < 5 && (
                  <div className="nextpage-arrow">
                    <button
                      type="button"
                      className="nextpage-arrow-button"
                      onClick={() => handleNextClick(formik)}
                    >
                      <i className="fa-solid fa-greater-than"></i>
                    </button>
                  </div>
                )}

                <div className="d-flex justify-content-center align-items-center mt-4">
                  <button type="button" className="skip-button">
                    Skip
                  </button>
                  <button
                    type="button"
                    className="next-button ml-4"
                    onClick={() =>
                      tab < 5 ? handleNextClick(formik) : formik.handleSubmit()
                    }
                  >
                    {tab < 5 ? "Next" : "Submit"}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default PlayerSignup;
