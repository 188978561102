import React from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import ArrowRight from "../../assets/arrow_right.svg";
import PaypalLogo from "../../assets/PayPal-Logo.svg";
import PicPay from "../../assets/picpay-logo.svg";
import ApplePay from "../../assets/apple-pay.svg";
import MasterCard from "../../assets/master-card.svg";
import ViSa from "../../assets/visa-logo.svg";

// Validation schema using Yup
const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  cardNumber: Yup.string()
    .required("Card number is required")
    .matches(/^\d{16}$/, "Card number must be 16 digits"),
  expiry: Yup.string()
    .required("Expiry date is required")
    .matches(/^(0[1-9]|1[0-2])\/\d{2}$/, "Expiry must be in MM/YY format"),
  cvc: Yup.string()
    .required("CVC/CVV is required")
    .matches(/^\d{3,4}$/, "CVC/CVV must be 3 or 4 digits"),
});

// Initial form values for Formik
const initialValues = {
  name: "",
  cardNumber: "",
  expiry: "",
  cvc: "",
};

// Handle form submission
const handleSubmit = (values) => {
  console.log("Form Submitted", values);
  // Implement payment processing logic here
};

const Billing = () => {
  return (
    <div className="subs_billing_wrap mb-5">
      {/* Header Section */}
      <div className="Change_Subscription_header">
        <h1>My Profile</h1>
        <div className="subscription_tabs text-white d-flex align-items-center gap-2">
          <p>Subscription</p>
          <img src={ArrowRight} alt="Arrow Right" />
          <p>Change Subscription</p>
          <img src={ArrowRight} alt="Arrow Right" />
          <span>Billing</span>
        </div>
      </div>

      {/* Billing Form Section */}
      <div className="billing_forms_wrap">
        <div className="container">
          <h6 className="main_head">Payment</h6>
          <div className="row">
            {/* Left Column - Billing Preferences and Order Summary */}
            <div className="col-lg-6">
              {/* Billing Preferences */}
              <div className="billing_box_wrap mb-4">
                <h5>Billing Preferences</h5>
                <div className="pay_monthly_annualy mb-3">
                  <BillingOption label="Pay Monthly" price="$250/Month" />
                  <BillingOption label="Pay Annually" price="$2800/Year" />
                </div>
              </div>

              {/* Order Summary */}
              <OrderSummary />
            </div>

            {/* Right Column - Payment Methods */}
            <div className="col-lg-6">
              <div className="billing_box_wrap px-0">
                <h5 className="px-5">Online banking</h5>
                <PaymentMethods />
                {/* Credit/Debit Card Payment Form */}
                <CardPaymentForm />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// Billing Option Component
const BillingOption = ({ label, price }) => (
  <div className="pay_monthly_annualy_row d-flex align-items-center justify-content-between mb-4">
    <div className="radio-container">
      <label className="custom-radio">
        <input type="radio" name="radio-group" />
        <span className="radio-checkmark"></span>
        {label}
      </label>
    </div>
    <h4>{price}</h4>
  </div>
);

// Order Summary Component
const OrderSummary = () => (
  <div className="billing_box_wrap order_summary">
    <h6>Order summary</h6>
    <div className="order_summary_rows_wrap">
      <SummaryRow label="Subscription" detail="Gold" price="$250/Month" />
      <SummaryRow label="Ranking Plan" detail="Tier 1" price="$250/Month" />
      <SummaryRow label="Billing Frequency" detail="Yearly" />
      <SummaryRow label="Discount" detail="0%" />
      <SummaryRow label="Tax" detail="8%" />
      <SummaryRow label="Sub Total" detail="$3,024" />
      <div className="total_payable d-flex justify-content-between align-items-center">
        <h3>Total Payable</h3>
        <h3>$3,024</h3>
      </div>
    </div>
  </div>
);

// Summary Row Component
const SummaryRow = ({ label, detail, price }) => (
  <div className="order_summary_row d-flex justify-content-between align-items-center mb-2">
    <label className="custom-radio">{label}</label>
    <div>
      <p>{detail}</p>
      {price && <span>{price}</span>}
    </div>
  </div>
);

// Payment Methods Component
const PaymentMethods = () => (
  <div className="payment_modes_wrap">
    <PaymentMethod label="PayPal" logo={PaypalLogo} />
    <PaymentMethod label="PicPay" logo={PicPay} />
    <PaymentMethod label="Apple Pay" logo={ApplePay} />
    <PaymentMethod
      label="Credit/Debit card"
      logos={[MasterCard, ViSa]}
      isCard={true}
    />
  </div>
);

// Payment Method Component
const PaymentMethod = ({ label, logo, logos, isCard }) => (
  <div
    className={`payment_mode_row_wrap d-flex justify-content-between align-items-center ${
      isCard ? "px-4 mt-4 mb-4" : ""
    }`}
  >
    <div className="radio-container">
      <label className="custom-radio">
        <input type="radio" name="radio-group" />
        <span className="radio-checkmark"></span>
        {label}
      </label>
    </div>
    {isCard ? (
      <div className="d-flex gap-2 align-items-center">
        {logos.map((logo, index) => (
          <img key={index} src={logo} alt="" />
        ))}
      </div>
    ) : (
      <img src={logo} alt={`${label} logo`} />
    )}
  </div>
);

// Card Payment Form Component
const CardPaymentForm = () => (
  <div className="form_for_CC_DC">
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <Form>
          <InputField label="Name" name="name" />
          <InputField label="Card Number" name="cardNumber" />
          <div className="row">
            <InputField label="Expiry" name="expiry" colClass="col-md-6" />
            <InputField label="CVC/CVV" name="cvc" colClass="col-md-6" />
          </div>
          <div className="pay_now_btn_wrap">
            <button type="submit" disabled={isSubmitting}>
              Pay Now
            </button>
          </div>
        </Form>
      )}
    </Formik>
  </div>
);

// Input Field Component
const InputField = ({ label, name, colClass = "" }) => (
  <div className={`form_row ${colClass}`}>
    <label htmlFor={name}>{label}</label>
    <Field type="text" name={name} />
    <ErrorMessage name={name} component="div" className="error" />
  </div>
);

export default Billing;
