import React from 'react'
import searchLogo from ".././../assets/search.svg"
import Player from './Player'
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';




const Players = () => {
    return (
        <div>
            <h6 style={{ color: '#fff' }}>Players</h6>
            <div className="players">
                <div className="">
                    <div className="search-sectionn">
                        <img src={searchLogo} alt="" />
                        <input type="text" placeholder="Search" />
                    </div>
                </div>
                <div className="player-btn row">
                    {/* <button className="player-btn1 "> Filters  </button> */}

                    <DropdownButton
                        id="dropdown-button"
                        title="Filters"
                        className="player-dropdown custom-filter-dropdown player-btn1 col-12 col-md-6 col-lg-4 col-xl-2 mb-1 text-center"
                    >
                        <Dropdown.Item className="custom-dropdown-item">
                            <input type="checkbox" id="tierRanking" />
                            <label htmlFor="tierRanking"> Tier Ranking <i className="down-icon"></i></label>
                        </Dropdown.Item>
                        <Dropdown.Item className="custom-dropdown-item">
                            <input type="checkbox" id="premiumNonRanking" />
                            <label htmlFor="premiumNonRanking"> Premium NON-Ranking Paying Users</label>
                        </Dropdown.Item>
                        <Dropdown.Item className="custom-dropdown-item">
                            <input type="checkbox" id="basicNonRanking" />
                            <label htmlFor="basicNonRanking"> Basic NON-Ranking Paying Users</label>
                        </Dropdown.Item>
                        <Dropdown.Item className="custom-dropdown-item">
                            <input type="checkbox" id="professionalPlayers" />
                            <label htmlFor="professionalPlayers"> Professional Players</label>
                        </Dropdown.Item>
                        <Dropdown.Item className="custom-dropdown-item">
                            <input type="checkbox" id="amateurPlayers" />
                            <label htmlFor="amateurPlayers"> Amateur Player</label>
                        </Dropdown.Item>
                    </DropdownButton>   


                    <button className="col-12 col-md-6 col-lg-4 col-xl-2 mb-1 player-btn2 "> Trending Player  </button>
                    <button className="col-12 col-md-6 col-lg-4 col-xl-2 mb-1 player-btn3 "> Proffesional  </button>
                    <button className="col-12 col-md-6 col-lg-4 col-xl-2 mb-1 player-btn4 "> Amature  </button>
                    <button className="col-12 col-md-6 col-lg-4 col-xl-2 mb-1 player-btn5 "> Right  </button>
                    <button className="col-12 col-md-6 col-lg-4 col-xl-2 mb-1 player-btn6 "> Left  </button>


                </div>
            </div>
            <div>
          
            <Player />
            <Player />
            </div>

        </div>
    )
}

export default Players