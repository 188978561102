


import React from "react";
import { Field, ErrorMessage } from "formik";



const formatCPF = (value) => {
    // Remove non-digit characters
    const cleanValue = value.replace(/\D/g, "");

    // Apply CPF formatting
    if (cleanValue.length <= 14) {
        // Format CPF as xx.xxx.xxx/xxxx-xx
        return cleanValue
            .slice(0, 2) // Take first 2 digits
            .concat('.')
            .concat(cleanValue.slice(2, 5)) // Next 3 digits
            .concat('.')
            .concat(cleanValue.slice(5, 8)) // Next 3 digits
            .concat('/')
            .concat(cleanValue.slice(8, 12)) // Next 4 digits
            .concat('-')
            .concat(cleanValue.slice(12, 14)); // Last 2 digits
    }

    return cleanValue;
};

const handleNumericChange = (e, setFieldValue) => {
    const { value } = e.target;
    const cleanValue = value.replace(/\D/g, ""); // Remove non-numeric characters

    if (cleanValue.length <= 14) {
        const formattedCPF = formatCPF(cleanValue);
        setFieldValue("cpf", formattedCPF);
    }
};
const FirstTab = ({ setFieldValue }) => {
    return (
        <>
            <div className="mt-5 d-flex justify-content-center align-items-center">
                <h2 className="progressbar-below-form">Personal Details</h2>
            </div>

            <div className="Form_main_wrap_for_details">
                <div className="row">
                    <div className="col-sm-6">
                        <div className="form-group">
                            <label className="d-block">Full Name</label>
                            <Field
                                type="text"
                                className="player-body-input"
                                name="Name"
                            />
                            <ErrorMessage
                                name="Name"
                                component="div"
                                className="error-message text-danger"
                            />
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="form-group">
                            <label className="d-block">Mobile No.</label>
                            <Field
                                type="text"
                                className="player-body-input"
                                name="number"
                                inputMode="numeric"
                                onChange={(e) => handleNumericChange(e, setFieldValue)} 
                            />
                            <ErrorMessage
                                name="number"
                                component="div"
                                className="error-message text-danger"
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6">
                        <div className="">
                            <label
                                htmlFor="exampleFormControlTextarea1"
                                className="form-label"
                            >
                                Little about you
                            </label>
                            <Field
                                as="textarea"
                                className="form-control max-height-input"
                                name="about"
                                id="exampleFormControlTextarea1"
                                rows="3"
                                style={{ backgroundColor: '#171919', color: 'white' }}
                            />
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <label htmlFor="">Playing Since</label>
                        <Field
                            type="text"
                            className="player-body-input"
                            name="playingSince"
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6">
                        <div className="form-group">
                            <label className="d-block">Height</label>
                            <Field
                                type="text"
                                className="player-body-input"
                                name="currentHeight"
                            />
                            <ErrorMessage
                                name="currentHeight"
                                component="div"
                                className="error-message text-danger"
                            />
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="form-group">
                            <label className="d-block">Weight</label>
                            <Field
                                type="text"
                                className="player-body-input"
                                name="currentWeight"
                            />
                            <ErrorMessage
                                name="currentWeight"
                                component="div"
                                className="error-message text-danger"
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6">
                        <div className="form-group">
                            <label className="d-block">Location</label>
                            <Field
                                type="text"
                                className="player-body-input"
                                name="location"
                            />
                            <ErrorMessage
                                name="location"
                                component="div"
                                className="error-message text-danger"
                            />
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="form-group">
                            <label className="d-block">Date of Birth</label>
                            <div className="d-flex">
                                <Field
                                    type="text"
                                    className="player-body-input-2"
                                    name="birthDate.day"
                                    placeholder="Day"
                                />
                                <Field
                                    type="text"
                                    className="player-body-input-2 ml-2"
                                    name="birthDate.month"
                                    placeholder="Month"
                                />
                                <Field
                                    type="text"
                                    className="player-body-input-2 ml-2"
                                    name="birthDate.year"
                                    placeholder="Year"
                                />
                            </div>
                            <ErrorMessage
                                name="birthDateDay"
                                component="div"
                                className="error-message"
                            />
                            <ErrorMessage
                                name="birthDateMonth"
                                component="div"
                                className="error-message"
                            />
                            <ErrorMessage
                                name="birthDateYear"
                                component="div"
                                className="error-message"
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6">
                        <div className="form-group">
                            <label className="d-block">CPF ID</label>
                            <Field
                                type="text"
                                className="player-body-input"
                                name="cpf"
                                onChange={(e) => handleNumericChange(e, setFieldValue)}
                                maxLength="18" // Prevent more than 14 characters in input
                            />
                            <ErrorMessage
                                name="cpf"
                                component="div"
                                className="error-message text-danger"
                            />
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="form-group">
                            <label className="d-block">Gender</label>
                            <Field
                                as="select"
                                className="form-control player-body-input"
                                name="gender"
                                style={{ backgroundColor: '#171919', color: 'white' }}
                            >
                                <option value="">Select Gender</option>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                                <option value="other">Other</option>
                            </Field>
                            <ErrorMessage
                                name="gender"
                                component="div"
                                className="error-message text-danger"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default FirstTab;
