// import React, { useState, useRef, useEffect } from 'react';
// import './Video.css';
// import Footer from './Footer';
// import { FaPlayCircle, FaPauseCircle } from 'react-icons/fa'; // Import play and pause icons from react-icons

// function Video({ video, isActive }) {
//     const [playing, setPlaying] = useState(false);
//     const [muted, setMuted] = useState(false);
//     const [progress, setProgress] = useState(0);
//     const videoRef = useRef(null);

//     useEffect(() => {
//         if (isActive) {
//             videoRef.current.play();
//             setPlaying(true);
//         } else {
//             videoRef.current.pause();
//             setPlaying(false);
//         }
//     }, [isActive]);

//     useEffect(() => {
//         const updateProgress = () => {
//             if (videoRef.current) {
//                 setProgress((videoRef.current.currentTime / videoRef.current.duration) * 100);
//             }
//         };

//         const interval = setInterval(updateProgress, 1000);
//         return () => clearInterval(interval);
//     }, [playing]);

//     const handlePlayPause = () => {
//         if (playing) {
//             videoRef.current.pause();
//         } else {
//             videoRef.current.play();
//         }
//         setPlaying(!playing);
//     };

//     const handleMuteUnmute = () => {
//         videoRef.current.muted = !muted;
//         setMuted(!muted);
//     };

//     const handleVideoClick = () => {
//         handlePlayPause();
//     };

//     return (
//         <div className="video">
//             <video
//                 ref={videoRef}
//                 src={`https://mosis-backend.cradle.services/${video?.video}`}
//                 height="100%"
//                 className="video-player"
//                 muted={muted}
//                 loop
//                 onClick={handleVideoClick}
//             />
//             {/* Show play icon if video is paused */}
//             {!playing && (
//                 <div className="play-icon" onClick={handlePlayPause}>
//                     <FaPlayCircle />
//                 </div>
//             )}
//             {/* Show pause icon if video is playing */}
//             {/* {playing && (
//                 <div className="pause-icon" onClick={handlePlayPause}>
//                     <FaPauseCircle />
//                 </div>
//             )} */}
//             <Footer 
//                 playing={playing}
//                 onPlayPause={handlePlayPause}
//                 onMuteUnmute={handleMuteUnmute}
//                 muted={muted}
//                 progress={progress}
//             />
//         </div>
//     );
// }

// export default Video;

import React, { useState, useRef, useEffect } from 'react';
import './Video.css';
import Footer from './Footer';

function Video({ video, isActive }) {
    const [playing, setPlaying] = useState(false);
    const [muted, setMuted] = useState(false);
    const [progress, setProgress] = useState(0);
    const [duration, setDuration] = useState(0);
    const videoRef = useRef(null);

    // Start or pause playback based on isActive prop
    useEffect(() => {
        if (isActive) {
            videoRef.current.play().catch(error => {
                console.error('Error attempting to play', error);
            });
            setPlaying(true);
        } else {
            videoRef.current.pause();
            setPlaying(false);
        }
    }, [isActive]);

    // Update progress and duration
    useEffect(() => {
        const updateProgress = () => {
            if (videoRef.current) {
                setProgress((videoRef.current.currentTime / videoRef.current.duration) * 100);
                setDuration(videoRef.current.duration);
            }
        };

        // Start interval to update progress
        const interval = setInterval(updateProgress, 1000);
        return () => clearInterval(interval);
    }, [playing]);

    // Handle key down events for play/pause and volume control
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.code === 'Space') {
                event.preventDefault(); // Prevent default space bar action (scrolling)
                handlePlayPause(); // Toggle play/pause
            } else if (event.code === 'ArrowUp') {
                if (videoRef.current) {
                    videoRef.current.volume = Math.min(1, videoRef.current.volume + 0.1);
                }
            } else if (event.code === 'ArrowDown') {
                if (videoRef.current) {
                    videoRef.current.volume = Math.max(0, videoRef.current.volume - 0.1);
                }
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [playing]);

    // Toggle play/pause
    const handlePlayPause = () => {
        if (playing) {
            videoRef.current.pause();
        } else {
            videoRef.current.play().catch(error => {
                console.error('Error attempting to play', error);
            });
        }
        setPlaying(!playing);
    };

    // Toggle mute/unmute
    const handleMuteUnmute = () => {
        videoRef.current.muted = !muted;
        setMuted(!muted);
    };

    // Handle video click
    const handleVideoClick = () => {
        handlePlayPause();
    };

    return (
        <div className="video" onClick={handleVideoClick}>
            <video
                ref={videoRef}
                src={`https://mosis-backend.cradle.services/${video?.video}`}
                // height="100%"
                className="video-player"
                muted={muted}
                
            />
            <div className="video-overlay">
                <div className="video-duration">
                    {duration ? `${Math.floor(duration / 60)}:${Math.floor(duration % 60).toString().padStart(2, '0')}` : '00:00'}
                </div>
            </div>
            <Footer 
                playing={playing}
                onPlayPause={handlePlayPause}
                onMuteUnmute={handleMuteUnmute}
                muted={muted}
                progress={progress} 
                duration={duration}
            />
        </div>
    );
}

export default Video;
