import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { creatShoolSlice } from '../../../redux/slice/school';
import SoccerSchool from './SoccerSchool';
import AddTeam from './AddTeam';
import PersonChanges from './PersonChanges';
import { updateUserSlice } from '../../../redux/slice/user';
import { createSchoolApi, updateUserApi } from '../../../redux/api/user';

const validationSchemas = {
    1: yup.object().shape({
        Name: yup.string().required('School Name is required'),
        cnpj: yup.string().required('CNPJ ID is required'),
        Location: yup.string().required('Location is required'),       
        seriesId: yup.string().required('Series is required'),
    }),
    2: yup.object().shape({
        // Add validation schema for tab 2
    }),
    3: yup.object().shape({
        // Add validation schema for tab 3
    }),
};

const loginData = localStorage.getItem('loginData');

let getuserData = {};
if (loginData && loginData !== 'undefined') {
  try {
    getuserData = JSON.parse(loginData);
  } catch (error) {
    console.error('Error parsing JSON:', error);
  }
} else {
  console.warn('No valid login data found or it is the string "undefined".');
}



const userToken =getuserData?.token
const userid= getuserData?.user?._id


const initialValues = {
   
    seriesId: '66e5753f8c7ac135c6721846',
    Name: '',
    cnpj: '',
    aboutSchool: '',
    established_date: "",

    Location: '',
    contact_persons: [
      {
        name:"",
        email:'',
        position: '',
        number: '',
        gender:''

      }
    ]
};



const School = () => {
    const [tab, setTab] = useState(1);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // const userData = JSON.parse(localStorage.getItem('userSignup'));
    const UserRole = localStorage.getItem('UserRole');

    const handleNextClick = async (formik) => {
        try {
            await validationSchemas[tab].validate(formik.values, { abortEarly: false });
            if (tab < 3) {
                setTab(tab + 1); // Move to the next tab
            }
        } catch (err) {
            console.error('Validation Error:', err);
            if (err.inner && Array.isArray(err.inner)) {
                const errors = err.inner.reduce((acc, curr) => {
                    acc[curr.path] = curr.message;
                    return acc;
                }, {});
                formik.setErrors(errors);
                formik.setTouched(Object.keys(errors).reduce((acc, curr) => {
                    acc[curr] = true;
                    return acc;
                }, {}));
            } else {
                toast.error('An unexpected validation error occurred.');
            }
        }
    };

    const onSubmit = async (values, { setSubmitting }) => {
        
      // const { established_date } = values;
      const formattedBirthDate = `${values.established_date?.day || ''}-${values.established_date?.month || ''}-${values.established_date?.year || ''}`;

       
        values.established_date = formattedBirthDate;

        const newVal = {
            ...values,
            userId:userid,
            role: UserRole,
            token:userToken
        };
        debugger
        try {
            const res = await createSchoolApi(newVal);
     
            if (res.isSuccess=== true) {
               const userRoleResponse = await updateUserApi({ id: userid,token:userToken, data: { role: UserRole } });   
             if (userRoleResponse?.data?.role) {
                    localStorage.setItem('loginData',  JSON.stringify(newVal));
                    navigate("/soccer_school"); 
                    toast.success('School Registration successful!');
                } else {
                    throw new Error('Failed to update user role');
                }
            } else {
                toast.error('School Registration failed!');
            }
        } catch (error) {
            toast.error('An error occurred during registration!');
            console.error("Error during registration:", error);
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <div className=''>
            <div className='progressbar d-flex flex-column align-items-center'>
                <div className='text-center'>
                    <span className='progressbar-header'>You are almost there!</span>
                </div>

                <div className='mt-3 align-items-center'>
                    <div className='d-flex align-items-center'>
                        <div className='progress'>
                            <div className='progress-bar bg-success' style={{ width: `${(tab / 3) * 100}%` }}></div>
                        </div>
                        <span className='ml-4'>{Math.round((tab / 3) * 100)}%</span>
                    </div>

                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchemas[tab]}
                        onSubmit={onSubmit}
                    >
                        {(formik) => (
                            <Form className='mt-4'>
                                {tab === 1 && <SoccerSchool setFieldValue={formik.setFieldValue} />}
                                {tab === 2 && <PersonChanges setFieldValue={formik.setFieldValue} />}
                                {tab === 3 && <AddTeam setFieldValue={formik.setFieldValue} />}
                                {tab > 1 && (
                  <div className="previouspage-arrow">
                    <button
                      type="button"
                      className="nextpage-arrow-button"
                      onClick={() => setTab(tab - 1)}
                    >
                      <i className="fa-solid fa-less-than"></i>
                    </button>
                  </div>
                )} 
                {tab < 3 && (
                  <div className="nextpage-arrow">
                    <button
                      type="button"
                      className="nextpage-arrow-button"
                      onClick={() => handleNextClick(formik)}
                    >
                      <i className="fa-solid fa-greater-than"></i>
                    </button>
                  </div>
                )}

                <div className="d-flex justify-content-center align-items-center mt-4">
                  <button type="button" className="skip-button">
                    Skip
                  </button>
                  <button
                    type="button"
                    className="next-button ml-4"
                    onClick={() =>
                      tab < 3 ? handleNextClick(formik) : formik.handleSubmit()
                    }
                  >
                    {tab < 3 ? "Next" : "Submit"}
                  </button>
                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
            <div className='nextpage-arrow'>
                <button className='nextpage-arrow-button' 
                 onClick={() => setTab(tab - 1)}
                 disabled={tab === 1}
                >
                    <i className='fa-solid fa-greater-than'></i>
                </button>
            </div>
        </div>
    );
};

export default School;
