import React, { useState } from "react";
import club_1 from "../../../../assets/images 1.png";
import club_2 from "../../../../assets/attachment_75814451 1.png";
import club_3 from "../../../../assets/1600w-9HW6TJQAkcg 1.png";
import club_4 from "../../../../assets/Group 1000003794.png";
import club_5 from "../../../../assets/attachment_105464680 2.png";
import club_6 from "../../../../assets/attachment_67870085 2.png";
import club_7 from "../../../../assets/2000w_q95 2.png";
import { Formik } from "formik";

const ClubTab = ({ setFieldValue }) => {
  const [selectedClub, setselectedClub] = useState("");

  const handleClubClick = (clubId) => {
    setselectedClub(clubId);
    setFieldValue("currentClubId", clubId);
  };
  return (
    <>
      <div className="mt-3 align-items-center">
        <div className="mt-5 d-flex justify-content-center align-items-center">
          <h2 className="progressbar-below-form">Are you a part of Club?</h2>
        </div>
        <div className="Club_search_main_wrap d-flex justify-content-center align-items-center">
          <input
            className="club-search"
            type="search"
            placeholder="Search"
            aria-label="Search"
          />
        </div>

        <div className="mb-5">
          <div className="row ">
            <div className="col-sm-6 col-md-6 col-lg-3 text-center mb-5">
              <div
                className={`club-image ${
                  selectedClub === "club_1" ? "selected" : ""
                }`}
                onClick={() => handleClubClick("667e9dbead7c85c8b8804b69")}
              >
                <img src={club_1} alt="Club 1" className="image-cursor" />
              </div>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-3 text-center mb-5">
              <div
                className={`club-image ${
                  selectedClub === "club_2" ? "selected" : ""
                } `}
                onClick={() => handleClubClick("667e9dbead7c85c8b8804b69")}
              >
                <img src={club_2} alt="Club 2" className="image-cursor" />
              </div>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-3 text-center mb-5">
              <div
                className={`club-image ${
                  selectedClub === "club_3" ? "selected" : ""
                } `}
                onClick={() => handleClubClick("667e9dbead7c85c8b8804b69")}
              >
                <img src={club_3} alt="Club 3" className="image-cursor" />
              </div>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-3 text-center mb-5">
              <div
                className={`club-image ${
                  selectedClub === "club_4" ? "selected" : ""
                } `}
                onClick={() => handleClubClick("667e9dbead7c85c8b8804b69")}
              >
                <img src={club_4} alt="Club 4" className="image-cursor" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 col-md-6 col-lg-3 text-center mb-5">
              <div
                className={`club-image ${
                  selectedClub === "club_5" ? "selected" : ""
                } `}
                onClick={() => handleClubClick("667e9dbead7c85c8b8804b69")}
              >
                <img src={club_5} alt="Club 5" className="image-cursor" />
              </div>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-3 text-center mb-5">
              <div
                className={`club-image ${
                  selectedClub === "club_6" ? "selected" : ""
                } `}
                onClick={() => handleClubClick("667e9dbead7c85c8b8804b69")}
              >
                <img src={club_6} alt="Club 6" className="image-cursor" />
              </div>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-3 text-center mb-5">
              <div
                className={`club-image ${
                  selectedClub === "club_7" ? "selected" : ""
                } `}
                onClick={() => handleClubClick("667e9dbead7c85c8b8804b69")}
              >
                <img src={club_7} alt="Club 7" className="image-cursor" />
              </div>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-3 text-center mb-5">
              <div
                className={`club-image ${
                  selectedClub === "club_8" ? "selected" : ""
                } `}
                onClick={() => handleClubClick("667e9dbead7c85c8b8804b69")}
              >
                <img src={club_1} alt="Club 8" className="image-cursor" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ClubTab;
