import PlayerSignup from "../pages/SigninSignUp/Player/PlayerSignup";
import SignIn from "../pages/SigninSignUp/SignIn/SignIn";
import Signup from "../pages/SigninSignUp/SignUp/Signup";
import SignUp_Role from "../pages/SigninSignUp/SignUpRole/SignUp_Role";
import SigninSignup from "../pages/SigninSignUp/SigninSignup";
import SimpleLayout from "./defaultLayout/SimpleLayout";

import Agent from "../pages/SigninSignUp/Agent/Agent";

import School from "../pages/SigninSignUp/School/School";
import Club from "../pages/SigninSignUp/Club/AmateurClub/Club";
import ProfessionalClub from "../pages/SigninSignUp/Club/ProfessionalClub/Club";




 export const SimpleRoute = [{
    
  path: "",
  element: <SimpleLayout/>,
  children: [
    // login / register page 
   
    {
      path: "Login",
      element: <SigninSignup />,
    },

    {
      path: "SignUp",
      element: <Signup />,
    },
    {
      path: "SignIn",
      element: <SignIn />,
    },
    {
      path: "SignUp-roles",
      element: <SignUp_Role />,
    },

    // player login  section route 
    {
      path: "role/player",
      element: <PlayerSignup />,
    },
    
   
    // agent login  section route////////// 
    {
      path: "role/agent",
      element: <Agent />,
    },
    
     // School login  section route 
     {
      path: "role/soccer_school",
      element: <School />,
    },
     // club login  section route 
     {
      path: "role/amateur_club",
      element: <Club />,
    },

    {
      path: "role/professional_club",
      element: <ProfessionalClub />,
    },
  
   
  
 
  ]
}
   
  ];