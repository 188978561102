
import React, { useEffect } from "react";
import Sidebar from "../../pages/sidebar/Sidebar";
 
import SuggestionAside from "../../pages/SuggestionAside";
import { Outlet } from "react-router-dom";
import '../../pages/sidebar/Sidebar';
import Header from "../../components/header/header";
import Simpleheader from "../../components/header/Simpleheader";

const SimpleLayout = () => {
  useEffect(() => {
    // Your useEffect code here
  }, []);

  return (
    <div>
      
         <Simpleheader/>
      <div className="header-fixed">
        {/* Your header component goes here */}
      </div>
      {/* <div className="sidebar-fixed">
        <Sidebar/>
      </div> */}
      {/* <div className="suggestion-aside-fixed">
        <SuggestionAside /> 
      </div> */}
      <div className="container-fluid pt-100px">
        <div className="row">
          {/* <div className="col-2"></div>  */}
          <div className="col-12"> 
            <div className="row">
              <div className=" content_main_wrap">
              
                <Outlet/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SimpleLayout;
