import React from 'react'
import { useFormik } from "formik";
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { changePasswordApi } from '../../redux/api/user';

const Password = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const getRole = JSON.parse(localStorage.getItem("loginData"));
  const Userid = getRole?._id;  
  const userRole = getRole?.role;
  const userToken = getRole?.token;


console.log('getRole',getRole.token);



    const passwordFormik = useFormik({
        initialValues: {
          oldPassword: "",
          newPassword: "",
          confirmPassword: "",
        },
        validate: (values) => {
          const errors = {};
    
          // Validate old password
          if (!values.oldPassword) {
            errors.oldPassword = "Current password is required";
          } else if (values.oldPassword.length < 6) {
            errors.oldPassword =
              "Current password must be at least 6 characters long";
          }
    
          // Validate new password
          if (!values.newPassword) {
            errors.newPassword = "New password is required";
          }
    
          // Validate confirm password
          if (values.newPassword !== values.confirmPassword) {
            errors.confirmPassword = "Passwords do not match";
          }
    
          return errors;
        },
        onSubmit: async (values) => {
          try {
            const response = await changePasswordApi({oldPassword: values.oldPassword,
              newPassword: values.newPassword,
              id: Userid,
              token:userToken
            });
            console.log('response',response)
            if (response.isSuccess === true) {
              navigate(`/${userRole}/`);
    
              toast.success("Password changed successfully!");
            } else {
              toast.error("Error changing password");
            }
          } catch (error) {
            // console.error("Error:", error);
            toast.error("Error changing password");
          }
        },
      });
  return (
    <div>
         <form onSubmit={passwordFormik.handleSubmit}>
                <hr className="one-line" />

                <div>
                  <div className="Change-Password text-white mb-4">
                    <h6>Change Password</h6>
                    <p>
                      You will be asked to log in again with your new password
                      after you save your changes.
                    </p>
                  </div>

                  <div className="Change-Password row">
                    <div className="mb-3 col-sm-6">
                      <label
                        htmlFor="oldPassword"
                        className="form-label text-white"
                      >
                        Current Password
                      </label>
                      <input
                        type="password"
                        id="oldPassword"
                        name="oldPassword"
                        className="form-control custom-input"
                        placeholder="Current Password"
                        value={passwordFormik.values.oldPassword}
                        onChange={passwordFormik.handleChange}
                        onBlur={passwordFormik.handleBlur}
                      />
                      {passwordFormik.errors.oldPassword &&
                        passwordFormik.touched.oldPassword && (
                          <div className="text-danger">
                            {passwordFormik.errors.oldPassword}
                          </div>
                        )}
                    </div>
                  </div>

                  <div className="Change-Password">
                    <div className="row mb-5">
                      <div className="col-sm-6">
                        <label
                          htmlFor="newPassword"
                          className="form-label text-white"
                        >
                          New Password
                        </label>
                        <input
                          type="password"
                          id="newPassword"
                          name="newPassword"
                          className="form-control custom-input"
                          placeholder="New Password"
                          value={passwordFormik.values.newPassword}
                          onChange={passwordFormik.handleChange}
                          onBlur={passwordFormik.handleBlur}
                        />
                        {passwordFormik.errors.newPassword &&
                          passwordFormik.touched.newPassword && (
                            <div className="text-danger">
                              {passwordFormik.errors.newPassword}
                            </div>
                          )}
                      </div>
                      <div className="col-sm-6">
                        <label
                          htmlFor="confirmPassword"
                          className="form-label text-white"
                        >
                          Confirm Password
                        </label>
                        <input
                          type="password"
                          id="confirmPassword"
                          name="confirmPassword"
                          className="form-control custom-input"
                          placeholder="Confirm Password"
                          value={passwordFormik.values.confirmPassword}
                          onChange={passwordFormik.handleChange}
                          onBlur={passwordFormik.handleBlur}
                        />
                        {passwordFormik.errors.confirmPassword &&
                          passwordFormik.touched.confirmPassword && (
                            <div className="text-danger">
                              {passwordFormik.errors.confirmPassword}
                            </div>
                          )}
                      </div>
                    </div>
                  </div>

                  <div className="Change-Password-btn row justify-content-center">
                    <div className="col-auto">
                      <button type="submit">Save Changes</button>
                    </div>
                  </div>
                </div>
              </form>
    </div>
  )
}

export default Password