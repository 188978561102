import React from "react";
import ArrowDown from "../../assets/subs_arrow_down.svg";
import ArrowRight from "../../assets/arrow_right.svg";
import { Link } from "react-router-dom";
const ChangeSubscription = () => {
  const role = JSON.parse(localStorage.getItem("loginData"));
  const rolename = role?.role;

  return (
    <>
      <div className="Change_Subscription_main_wrap">
        <div className="Change_Subscription_header ">
          <h1>My Profile</h1>
          <div className="subscription_tabs text-white d-flex align-items-center gap-2">
            <p>Subscription</p> <img src={ArrowRight} alt="" />{" "}
            <span>Change Subscription</span>
          </div>
        </div>
        <div className="container-fluid">
          <div className="subscription_row mb-3 cursor d-flex justify-content-between align-items-center">
            <Link to={`/${rolename}/Billing`}>
              <h6>Select the Best Subscription Plan for You</h6>
            </Link>
            <img src={ArrowDown} alt="" />
          </div>
          <div className="subscription_row d-flex cursor justify-content-between align-items-center">
            <h6>Select the Best Tier Plan for You</h6>
            <img src={ArrowDown} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangeSubscription;
