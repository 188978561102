// import React, { useEffect } from "react";
// import CreateVideo from "./homeComponents/CreateVideo";
// import PlayersList from "./homeComponents/playerslist";
// import './sidebar/sidebar.css';
// import VideoSuggestion from "./homeComponents/VideoSuggestion";
// import MyVideoPage from "./Matches/MyVideoPage";

// const Home = () => {
//   useEffect(() => {
//     // Your useEffect code here
//   }, []);

//   return (
//     <>
//                  {/* <div className="home_head_wrap">
//                   <h6>Home</h6> 
//                 </div>     */}
//                 <CreateVideo/>
//                 <PlayersList/>
//                 <MyVideoPage />
//                 <VideoSuggestion/>  
//                 </>
                
             
//   );
// };

// export default Home;



import React, { useEffect, useState } from "react";
import CreateVideo from "./homeComponents/CreateVideo";
import PlayersList from "./homeComponents/playerslist";
import './sidebar/sidebar.css';
import VideoSuggestion from "./homeComponents/VideoSuggestion";
import MyVideoPage from "./Matches/MyVideoPage";

const Home = () => {
  const [showMyVideoPage, setShowMyVideoPage] = useState(false);

  useEffect(() => {
    const checkForNewReel = () => {
      const reelUploadStatus = localStorage.getItem('newReelUpload');
      // console.log('reelUploadStatus', reelUploadStatus)
      if (reelUploadStatus === 'success') {
        setShowMyVideoPage(true);
        setTimeout(() => {
          setShowMyVideoPage(false);
          localStorage.removeItem('newReelUpload'); // Clean up
        }, 10000); // Show for 10 seconds
      }
    };

    checkForNewReel(); // Check when component mounts

    window.addEventListener('storage', checkForNewReel); // Check on local storage changes

    return () => {
      window.removeEventListener('storage', checkForNewReel);
    };
  }, []);

  return (
    <>
      <CreateVideo />
      <PlayersList />
      {showMyVideoPage && <MyVideoPage />}
      <VideoSuggestion />
    </>
  );
};

export default Home;
