import React from "react";
import "./Profile.css";
import Videos from "../../assets/multi_vid_img.svg";
import BgBlur from "../../assets/blur-bg.svg";
import ArrowRight from "../../assets/arrow_right.svg";
import { myvideos } from "../../shared/mock.data";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Form from 'react-bootstrap/Form';


const PlaylistView = () => {
    const containerStyle = {
      backgroundImage: `url(${BgBlur})`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat', 
    };
  return (
    <div>
      <div className="playlist_Wrap">
        <div className="playlist_top_header_wrap">
          <h1>My Profile</h1>
          <div className="playlist_tabs d-flex align-items-center gap-2">
            <p>My Profile</p> <img src={ArrowRight} alt="" /> <p>Playlist</p>{" "}
            <img src={ArrowRight} alt="" /> <span>Liked Videos</span>
          </div>
        </div>
        <div className="liked_videos_box_wrap position-relative d-flex gap-4" style={containerStyle}>
      <div className="box_left_side">
        <img src={Videos} alt="Video Thumbnail" />
      </div>
      <div className="box_right_side">
        <h2>Liked Videos</h2>
        <div className="Last_updated d-flex gap-2 text-white align-items-center">
          <p>5 Videos</p> . <p>Last updated on 12 May 2024</p>
        </div>
        <div className="Dwnld_delete_add mt-3 d-flex gap-5">
          <i className="fa-solid fa-download"></i>
          <i className="fa-solid fa-trash"></i>
          <i className="fa-solid fa-plus"></i>
        </div>
        <div className="likedVideo-btn2 gap-2">
          <button className="btn1">
            <i className="fa-solid fa-play"></i>Play All
          </button>
          <button className="btn2">
            <i className="fa-solid fa-shuffle"></i>Shuffle
          </button>
        </div>
      </div>
    </div>
        {/* <div className="likedVideo d-flex">
                    <div className="likedVideo-img">
                        <img src={video} alt="" />
                    </div>
                    <div className="likedVideo-all">
                        <div className="likedVideo-text d-flex">
                            <div>
                            <div className='likedVideo-header1'> <h1>Liked Videos</h1></div>
                            <div className='likedVideo-header2'>
                                <span>5 Videos</span>
                                <span>.</span>
                                <span>Last updated on 12 May 2024</span>

                            </div>
                            </div>
                            <div>
                                <span><i class="fa-regular fa-pen-to-square"></i></span>
                            </div>
                           
                        </div>
                        <div className="likedVideo-all-btn d-flex">
                            <div className='likedVideo-btn2 d-flex'>
                                <span className=''><i class="fa-solid fa-download"></i></span>
                                <span className='ml-5'><i class="fa-solid fa-trash"></i></span>
                                <span className='ml-5'><i class="fa-solid fa-plus"></i></span>

                            </div>
                            <div className='likedVideo-btn2 d-flex'>
                              <button className='btn1'><i class="fa-solid fa-play"></i>Play All</button>
                              <button className='btn2'><i class="fa-solid fa-shuffle"></i>Shuffle</button>
                            </div>
                        </div>

                    </div>
                </div> */}

        <div className="view-text d-flex align-items-between justify-content-between">
          <div className="d-flex gap-4">
          <p className="btn-color">All</p>
          <p>Videos</p>
          <p>Reels </p>
          </div>
          <div className="Dropdown_wrap">
            <DropdownButton
              id="dropdown-custom-button"
              title={
                <span>
                  Recently Added <i className="fa-solid fa-chevron-down ml-2"></i>
                </span>
              }
            >
              <Dropdown.Item>
                <Form.Check type="checkbox" label="Recently Added" />
              </Dropdown.Item>
              <Dropdown.Item>
                <Form.Check type="checkbox" label="A to Z" />
              </Dropdown.Item>
              <Dropdown.Item>
                <Form.Check type="checkbox" label="Old to New" />
              </Dropdown.Item>
              <Dropdown.Item>
                <Form.Check type="checkbox" label="New to Old" />
              </Dropdown.Item>
            </DropdownButton>
          </div>
        </div>
        <div className="all-videosView">
          <div className="playList">
            <div className="VideoSuggesions_main_wrap">
              {/* <div className="reels_top_wrap d-flex gap-12px align-items-center">
            <img src={VideoIcon} alt="" />
            <h6>Videos</h6>
          </div> */}
              <div className="For_Videos">
                <div class="">
                  <div class="row">
                    {myvideos.map((item) => (
                      <div
                        key={item.id}
                        class="col-12 col-md-6 col-lg-4 col-xl-3 mb-4"
                      >
                        <div class="card">
                          <div class="card-body">
                            <div className="card_img_wrap">
                              <img src={item.videocover} alt="" />
                            </div>
                            <div className="match_info_subtitles">
                              <div className="title_wrap d-flex gap-10px">
                                {/* <img src={item.vdotitledp} alt="" /> */}
                                <div>
                                  <h5>{item.title}</h5>
                                  <h6>{item.profession}</h6>

                                  <p className="blue-text">
                                    <a href="">
                                      <h6>{item.viewsonvid}</h6>
                                    </a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlaylistView;
