// import React from 'react'

// const GeoChart = () => {
//   return (
//     <div>GeoChart</div>
//   )
// }

// export default GeoChart




import React, { useEffect } from 'react';

const GeoChart = () => {
  useEffect(() => {
    const loadGoogleCharts = () => {
      // Load Google Charts API script dynamically
      const script = document.createElement('script');
      script.src = 'https://www.gstatic.com/charts/loader.js';
      script.onload = () => {
        // Initialize Google Charts API
        window.google.charts.load('current', {
          packages: ['geochart'],
        });
        window.google.charts.setOnLoadCallback(drawRegionsMap);
      };
      document.body.appendChild(script);
    };

    const drawRegionsMap = () => {
      // Draw the chart using Google Charts API
      const data = window.google.visualization.arrayToDataTable([
        ['Country', 'Popularity'],
        ['Germany', 200],
        ['United States', 300],
        ['India',375],
        ['Brazil', 400],
        ['Canada', 500],
        ['France', 600],
        ['RU', 700],
      ]);

      // Define colors based on data values
      const colorAxis = {
        // minValue: 0,
        // maxValue: 700,
        colors: ['#f0f9e8', '#F0FFFF',"#ADD8E6", '#89CFF0', '#43a2ca', '#0868ac'], // Light blue color range
      };

      // Options for the GeoChart
      const options = {
        backgroundColor: '#191B1B',  // Set a light gray background color
        datalessRegionColor: '#ffff',  // Set a light gray color for regions with no data
        colorAxis: colorAxis,  // Apply color axis
      };

      // Select the DOM element where the chart will be rendered
      const chart = new window.google.visualization.GeoChart(document.getElementById('regions_div'));

      // Draw the chart with the data and options
      chart.draw(data, options);
    };

    // Function to load Google Charts API script and initialize chart drawing
    loadGoogleCharts();

    // Clean up function to remove the Google Charts API script when component unmounts
    return () => {
      const scripts = document.getElementsByTagName('script');
      // Remove all dynamically added script tags
      for (let i = 0; i < scripts.length; i++) {
        if (scripts[i].src.indexOf('https://www.gstatic.com/charts/loader.js') !== -1) {
          document.body.removeChild(scripts[i]);
        }
      }
    };
  }, []); // Empty dependency array ensures useEffect runs only once on mount

  // Render the div where the chart will be displayed
  return (
    <div id="regions_div" style={{ width: '400px', height: '260px' }}></div>
  );
};

export default GeoChart;

