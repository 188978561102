import { ErrorMessage, Field } from 'formik';
import React from 'react';

function ClubDetail() {
    return (
        <div>
            <div className="mt-5 d-flex justify-content-center align-items-center">
                <h2 className="progressbar-below-form">Club Details</h2>
            </div>
            <div className='Form_main_wrap_for_details'>
                <div className='row'>
                    <div className="col-sm-6">
                        <div className="form-group mb-4">
                            <label className="d-block">Club Name</label>
                            <Field type="text" className="player-body-input p-4" name="Name" />
                            <ErrorMessage name="Name" component="div" className="error-message text-danger" />
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="form-group mb-4">
                            <label className="d-block">Date</label>
                            <div className="d-flex">
                                <Field type="text" className=" player-body-input-2 mr-2" name="established_date.day" placeholder="Day" />
                                <Field type="text" className="player-body-input-2 mx-2" name="established_date.month" placeholder="Month" />
                                <Field type="text" className="player-body-input-2 ml-2" name="established_date.year" placeholder="Year" />
                            </div>
                            <ErrorMessage name="established_date" component="div" className="error-message" />
                            <ErrorMessage name="month" component="div" className="error-message" />
                            <ErrorMessage name="year" component="div" className="error-message" />
                        </div>
                    </div>



                </div>
                <div class="mb-3">
                    <label htmlFor="exampleFormControlTextarea1" className="form-label mb-4">About Club</label>
                    <Field as="textarea" id="aboutSchool" name="aboutClub" className="form-control player-body-input-1" rows="3" />

                    <ErrorMessage name="aboutClub" component="div" className="error-message" />
                </div>
                <div className='row'>
                    <div className="col-sm-6">
                        <div className="form-group mb-4">
                            <label className="d-block">City</label>
                            <Field type="text" className="player-body-input p-4" name="city" />
                            <ErrorMessage name="city" component="div" className="error-message text-danger" />
                        </div>

                    </div>
                    <div className="col-sm-6">
                        <div className="form-group mb-4">
                            <label className="d-block">State</label>
                            <Field type="text" className="player-body-input p-4" name="state" />
                            <ErrorMessage name="state" component="div" className="error-message text-danger" />
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className="col-sm-6">
                        <div className="form-group mb-4">
                            <label className="d-block">Country</label>
                            <Field type="text" className="player-body-input p-4" name="country" />
                            <ErrorMessage name="country" component="div" className="error-message text-danger" />
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="form-group mb-4">
                            <label className="d-block">Website link</label>
                            <Field type="text" className="player-body-input p-4" name="websiteLink" />
                            <ErrorMessage name="websiteLink" component="div" className="error-message text-danger" />
                        </div>
                    </div>



                </div>
                <div className="row">
                    <div className="col-sm-6">
                        <div className="form-group mb-4">
                            <label className="d-block">CNPJ ID  </label>
                            <Field type="text" className="player-body-input p-4" name="cnpj" />
                            <ErrorMessage name="cnpj" component="div" className="error-message text-danger" />
                        </div>
                    </div>

                </div>

            </div>
        </div>
    );
}

export default ClubDetail;
