// import React from "react";
// import "./Negotiation.css";
// const NegotiationModal = ({ isOpen, onClose }) => {
//   return (
//     <>
//       {isOpen && (
//         <div
//           className="modal fade show"
//           id="exampleModalCenter"
//           tabIndex="-1"
//           role="dialog"
//           aria-labelledby="exampleModalCenterTitle"
//           aria-hidden="true"
//           style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
//         >
//           <div
//             className="modal-dialog modal-dialog-centered"
//             role="document"
//             style={{ maxWidth: "500px" }}
//           >
//             <div className="modal-content" style={{ minHeight: "300px" }}>
//               <div className="modal-header modalheader">
//                 <h5 className="modal-title">
//                   <i className="fa-solid fa-link"></i>
//                   <span> Link</span>
//                 </h5>
//                 <button
//                   type="button"
//                   className="close"
//                   onClick={onClose}
//                   aria-label="Close"
//                 >
//                   <span aria-hidden="true">&times;</span>
//                 </button>
//               </div>
//               <div className="modal-body">
//                 <div className="form-group">
//                   <label htmlFor="inputLink">Link URL</label>
//                   <input
//                     type="text"
//                     className="form-control"
//                     id="inputLink"
//                     placeholder="Enter URL"
//                   />
//                 </div>
//                 <div className="form-group">
//                   <div className="form-check form-switch">
//                     <input
//                       className="form-check-input"
//                       type="checkbox"
//                       id="flexSwitchCheckDefault"
//                     />
//                     <label
//                       className="form-check-label"
//                       htmlFor="flexSwitchCheckDefault"
//                     >
//                       Open a new tab
//                     </label>
//                   </div>
//                 </div>
//               </div>
//               <div className="modalfooter">
//                 <button type="button" className="btn1 ">
//                   Add
//                 </button>
//                 <button type="button" className="btn2 " onClick={onClose}>
//                   Cancel
//                 </button>
//               </div>
//             </div>
//           </div>
//         </div>
//       )}
//     </>
//   );
// };

// export default NegotiationModal;



import React, { useState } from "react";
import "./Negotiation.css";

const NegotiationModal = ({ isOpen, onClose, onAddUrl }) => {
  const [url, setUrl] = useState('');

  const handleAdd = () => {
    if (url) {
      onAddUrl(url);
    }
  };

  return (
    <>
      {isOpen && (
        <div
          className="modal fade show"
          id="exampleModalCenter"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
          style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        >
          <div
            className="modal-dialog modal-dialog-centered"
            role="document"
            style={{ maxWidth: "500px" }}
          >
            <div className="modal-content" style={{ minHeight: "300px" }}>
              <div className="modal-header modalheader">
                <h5 className="modal-title">
                  <i className="fa-solid fa-link"></i>
                  <span> Link</span>
                </h5>
                <button
                  type="button"
                  className="close"
                  onClick={onClose}
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="form-group">
                  <label htmlFor="inputLink">Link URL</label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputLink"
                    placeholder="Enter URL"
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="flexSwitchCheckDefault"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexSwitchCheckDefault"
                    >
                      Open a new tab
                    </label>
                  </div>
                </div>
              </div>
              <div className="modalfooter">
                <button type="button" className="btn1" onClick={handleAdd}>
                  Add
                </button>
                <button type="button" className="btn2" onClick={onClose}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default NegotiationModal;
