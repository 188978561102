import React from 'react'
import { PlayersProfile } from '../../shared/mock.data'
import { useNavigate } from 'react-router-dom';



const Player = () => {
  const role = JSON.parse(localStorage.getItem('loginData')) 
  const rolename= role?.role 


const navigate = useNavigate();
  return (
    <div>
 <div>
      <div className="players_list_main_wrap">
      <div className="container">
      <div className="row flex_wrap_wrap">
      {PlayersProfile.map((item) => (
        <div key={item.id} className="width-202px col-md-12 mb-4">
          <div className="card custom-card">
            <div className="card-body">
             <img src={item.src} alt="" />
             <h6>{item.title}</h6>
             <button onClick={() => navigate(`/${rolename}/playerprofile`)} >{item.btn}</button>
            </div>
          </div>
        </div>
      ))}
      </div>
    </div>
      </div>
    </div>

    

    </div>
  )
}

export default Player