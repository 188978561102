import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AgentTab from './AgentTab';
import AgentSecTwo from './AgentSecTwo';
import AgentClubTab from './AgentClubTab';
import AgentSchoolTab from './AgentSchoolTab';
import { creatAgentSlice } from '../../../redux/slice/Agent';
import { toast } from 'react-toastify';
import { updateUserApi } from '../../../redux/api/user';

const loginData = localStorage.getItem('loginData');
let getuserData = {};
if (loginData && loginData !== 'undefined') {
  try {
    getuserData = JSON.parse(loginData);
  } catch (error) {
    console.error('Error parsing JSON:', error);
  }
}


const userToken =getuserData?.token
const userid= getuserData?.user?._id



const validationSchemas = {
    1: yup.object().shape({
        Name: yup.string().required('Full Name is required'),
        location: yup.string().required('Location is required'),
        about: yup.string().required('Little about you is required'),
        cpf: yup.string().required('CPF ID is required'),
        
    }),
    2: yup.object().shape({
        // Achievements: yup.string().required('Give us an overview of your Achievements is required')
    }),
    3: yup.object().shape({
    }),
    4: yup.object().shape({
    }),

};



const initialValues = {   

linked_clubsId: "667e9dbead7c85c8b8804b69",
  linked_schoolsId: "667e9dbead7c85c8b8804b69",
  Name: getuserData?.user?.Name || "",
  cpf: "",

  number: getuserData?.user?.number || "",

  experience: [
    {
      location: "",
      achievements: "",
      startDate: "",
      endDate: "",
      player: ""
    }
  ],
  about: "",
  location: "",
  dateOfBirth: ""

};


const formatDate = (year, month, day) => {
    const formattedDay = day.padStart(2, '0');
    const formattedMonth = month.padStart(2, '0');
    return `${year}-${formattedMonth}-${formattedDay}`;
};

const Agent = () => {
    const [tab, setTab] = useState(1);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const UserRole = localStorage.getItem('UserRole');

    const handleNextClick = async (formik) => {
        
        try {
            await validationSchemas[tab].validate(formik.values, { abortEarly: false });
            if (tab < 4) {
                setTab(tab + 1); // Move to the next tab
            }
        } catch (err) {
            console.error('Validation Error:', err);
            if (err.inner && Array.isArray(err.inner)) {
                const errors = err.inner.reduce((acc, curr) => {
                    acc[curr.path] = curr.message;
                    return acc;
                }, {});
                formik.setErrors(errors);
                formik.setTouched(Object.keys(errors).reduce((acc, curr) => {
                    acc[curr] = true;
                    return acc;
                }, {}));
            } else {
                toast.error('An unexpected validation error occurred.');
            }
           
        }
    };

  

    const onSubmit = async (values, { setSubmitting }) => {
        const { dateOfBirth } = values;
        const formattedBirthDate = formatDate(dateOfBirth.year, dateOfBirth.month, dateOfBirth.day);
        values.dateOfBirth = formattedBirthDate;

        const newVal = {
            ...values,
            userId: userid,
            role: UserRole,
            token:userToken
            
        };

        try {
            const res = await dispatch(creatAgentSlice(newVal));

            
            if (res.payload.isSuccess === true) {
                setSubmitting(false);
               
                localStorage.setItem('loginData', JSON.stringify(newVal));
                const userRoleResponse = await updateUserApi({ id: userid,token:userToken,  data: { role: UserRole } });
                
                if (userRoleResponse?.data?.role) {
                    localStorage.setItem('loginData', JSON.stringify(newVal));
                    navigate("/agent");
                    toast.success('Agent Registration successful!');
                } else {
                    throw new Error('Failed to update user role');
                }

            } else {
                toast.error('Agent Registration failed!');
            }
        } catch (error) {
            console.error('Agent Registration failed:', error);
            toast.error('Agent Registration failed!');
        } finally {
            setSubmitting(false);
        }
    }

    return (
        <div>
            <div className='progressbar d-flex flex-column align-items-center'>
                <div className='text-center'>
                    <span className='progressbar-header'>You are almost there!</span>
                </div>

                <div className='mt-3 align-items-center'>
                    <div className='d-flex align-items-center'>
                        <div className='progress'>
                            <div
                                className='progress-bar bg-success'
                                style={{ width: `${(tab / 4) * 100}%` }}
                            ></div>
                        </div>
                        <span className='ml-4'>{(tab / 4) * 100}%</span>
                    </div>

                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchemas[tab]}
                        onSubmit={onSubmit}
                    >
                        {(formik) => (
                            <Form className='mt-4'>
                                {tab === 1 && <AgentTab setFieldValue={formik.setFieldValue} />}
                                {tab === 2 && <AgentSecTwo setFieldValue={formik.setFieldValue} />}
                                {tab === 3 && <AgentClubTab setFieldValue={formik.setFieldValue} />}
                                {tab === 4 && <AgentSchoolTab setFieldValue={formik.setFieldValue} />}

                                {tab > 1 && (
                  <div className="previouspage-arrow">
                    <button
                      type="button"
                      className="nextpage-arrow-button"
                      onClick={() => setTab(tab - 1)}
                    >
                      <i className="fa-solid fa-less-than"></i>
                    </button>
                  </div>
                )} 
                {tab < 4 && (
                  <div className="nextpage-arrow">
                    <button
                      type="button"
                      className="nextpage-arrow-button"
                      onClick={() => handleNextClick(formik)}
                    >
                      <i className="fa-solid fa-greater-than"></i>
                    </button>
                  </div>
                )}

                <div className="d-flex justify-content-center align-items-center mt-4">
                  <button type="button" className="skip-button">
                    Skip
                  </button>
                  <button
                    type="button"
                    className="next-button ml-4"
                    onClick={() =>
                      tab < 4 ? handleNextClick(formik) : formik.handleSubmit()
                    }
                  >
                    {tab < 4 ? "Next" : "Submit"}
                  </button>
                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
            <div className='nextpage-arrow'>
                <button
                    className='nextpage-arrow-button'
                    onClick={() => setTab(tab - 1)}
                    disabled={tab === 1}
                >
                    <i className='fa-solid fa-greater-than'></i>
                </button>
            </div>
        </div>
    );
};

export default Agent;
