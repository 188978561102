// import { ErrorMessage, Field } from 'formik'
// import React from 'react'

// function SoccerSchool() {
//     return (
//         <div>
//             <div className='mt-5 d-flex justify-content-center align-items-center'>
//                 <span className='progressbar-footer mt-5'>School Details</span>
//             </div>
//             <form className='mt-4' >
//                 <div className='d-flex gap-4'>
//                     <div className="form-group mb-4">
//                         <label className="d-block">School name</label>
//                         <Field type="text" className="player-body-input p-4" name="schoolName" placeholder="" />
//                         <ErrorMessage name="schoolName" component="div" className="error-message" />
//                     </div>
//                     <div className="form-group mb-4 ml-5">
//                         <label className="d-block">CNPJ ID</label>
//                         <Field type="text" className="player-body-input p-4" name="cnpjId" placeholder="" />
//                         <ErrorMessage name="cnpjId" component="div" className="error-message" />
//                     </div>
//                 </div>
//                 <div class="mb-3">
//                     <label for="exampleFormControlTextarea1" class="form-label mb-4">About School</label>
//                     <Field as="textarea" id="aboutSchool" name="aboutSchool" class="form-control player-body-input-1 " rows="3" />
//                     <ErrorMessage name="aboutSchool" component="div" className="error-message" />
//                 </div>

//                 <div className='d-flex gap-4'>
//                     <div className="form-group mb-4">
//                         <label className="d-block">Location</label>
//                         <Field type="text" className=" player-body-input p-4" name="location" placeholder="" />
//                         <ErrorMessage name="location" component="div" className="error-message" />
//                     </div>
//                     <div className="form-group mb-4 ml-5">
//                         <label className="d-block">Established Date</label>
//                         <div className="d-flex">
//                             <Field type="text" className=" player-body-input-2    mr-2" name="day" placeholder="Day" />
//                             <Field type="text" className="player-body-input-2   mx-2" name="month" placeholder="Month" />
//                             <Field type="text" className="player-body-input-2 ml-2" name="year" placeholder="Year" />
//                         </div>
//                         <ErrorMessage name="day" component="div" className="error-message" />
//                         <ErrorMessage name="month" component="div" className="error-message" />
//                         <ErrorMessage name="year" component="div" className="error-message" />
//                     </div>
//                 </div>
//                 <div className="form-group mb-4">
//                     <label className="d-block">Series</label>
//                     <Field type="text" className="player-body-inputt p-4" name="series" placeholder="" />
//                     <ErrorMessage name="series" component="div" className="error-message" />
//                 </div>


//             </form>

//         </div>
//     )
// }

// export default SoccerSchool


import { ErrorMessage, Field } from 'formik';
import React from 'react';

function SoccerSchool() {
    return (
        <div>
            <div className="mt-5 d-flex justify-content-center align-items-center">
                <h2 className="progressbar-below-form">School Details</h2>
            </div>
            <div className='Form_main_wrap_for_details'>
                <div className='row'>
                    <div className="col-sm-6">
                        <div className="form-group mb-4">
                            <label className="d-block">School name</label>
                            <Field type="text" className="player-body-input p-4" name="Name" />
                            <ErrorMessage name="Name" component="div" className="error-message text-danger" />
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="form-group mb-4">
                            <label className="d-block">CNPJ ID</label>
                            <Field type="text" className="player-body-input p-4" name="cnpj" />
                            <ErrorMessage name="cnpj" component="div" className="error-message text-danger" />
                        </div>
                    </div>


                </div>
                <div class="mb-3">
                    <label htmlFor="exampleFormControlTextarea1" className="form-label">About School</label>
                    <Field as="textarea" id="aboutSchool" name="aboutSchool" className="form-control player-body-input-1" rows="3" />

                    <ErrorMessage name="aboutSchool" component="div" className="error-message" />
                </div>
                <div className='row'>
                    <div className="col-sm-6">
                        <div className="form-group mb-4">
                            <label className="d-block">Location</label>
                            <Field type="text" className=" player-body-input p-4" name="Location" />
                            <ErrorMessage name="Location" component="div" className="error-message text-danger" />
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="form-group mb-4">
                            <label className="d-block">Established Date</label>
                            <div className="d-flex">
                                <Field type="text" className=" player-body-input-2 mr-2" name="established_date.day" placeholder="Day" />
                                <Field type="text" className="player-body-input-2 mx-2" name="established_date.month" placeholder="Month" />
                                <Field type="text" className="player-body-input-2 ml-2" name="established_date.year" placeholder="Year" />
                            </div>
                            <ErrorMessage name="established_date" component="div" className="error-message" />
                            <ErrorMessage name="month" component="div" className="error-message" />
                            <ErrorMessage name="year" component="div" className="error-message" />
                        </div>
                    </div>


                </div>
                <div className="form-group mb-4">
                    <label className="d-block">Series</label>
                    <Field type="text" className="player-body-input p-4" name="seriesId" />
                    <ErrorMessage name="seriesId" component="div" className="error-message text-danger" />
                </div>
            </div>
        </div>
    );
}

export default SoccerSchool;
