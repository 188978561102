import React from 'react';
import { FieldArray, Field } from 'formik';

const ClubContacts = () => {
  return (
    <FieldArray
      name="contact_persons"
      render={arrayHelpers => (
        <div>
          <div className="mt-5 d-flex justify-content-center align-items-center">
            <h2 className="progressbar-below-form">Club Contacts</h2>
          </div>
          {arrayHelpers.form.values.contact_persons.map((contact, index) => (
            <div key={index} className="mb-4">
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group mb-4">
                    <label className="d-block">Full Name</label>
                    <Field
                      type="text"
                      className="player-body-input p-4"
                      name={`contact_persons[${index}].name`}
                      placeholder=""
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group mb-4">
                    <label className="d-block">Position</label>
                    <Field
                      type="text"
                      className="player-body-input p-4"
                      name={`contact_persons[${index}].position`}
                      placeholder=""
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group mb-4">
                    <label className="d-block">Mobile No.</label>
                    <Field
                      type="text"
                      className="player-body-input p-4"
                      name={`contact_persons[${index}].number`}
                      placeholder=""
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group mb-4">
                    <label className="d-block">Email ID</label>
                    <Field
                      type="text"
                      className="player-body-input p-4"
                      name={`contact_persons[${index}].email`}
                      placeholder=""
                    />
                  </div>
                </div>
              </div>
              {arrayHelpers.form.values.contact_persons.length > 1 && (
                <div className="experience-remove mt-4 text-danger">
                  <p onClick={() => arrayHelpers.remove(index)}>Remove Contact</p>
                </div>
              )}
            </div>
          ))}
          <div className="experience-add mt-4">
            <p onClick={() => arrayHelpers.push({ name: '', position: '', number: '', email: '' })}>+ Add More</p>
          </div>
        </div>
      )}
    />
  );
};

export default ClubContacts;
