// import { ErrorMessage, Field } from "formik";
// import React from "react";

// const PersonChanges = () => {
//   return (
//     <div>
//       <div className="mt-5 d-flex justify-content-center align-items-center">
//         <h2 className="progressbar-below-form">Person In charge</h2>
//       </div>
//       <div className="Form_main_wrap_for_details">
//         {/* <form> */}
//         <div className="row">
//           <div className="col-sm-6">
//             <div className="form-group">
//               <label className="d-block">Full Name</label>
//               <Field
//                 type="text"
//                 className="player-body-input p-4"
//                 placeholder=""
//                 name="contact_persons[0].name"
//               />
//               <ErrorMessage
//                 name="contact_persons[0].name"
//                 component="div"
//                 className="text-danger"
//               />
//             </div>
//           </div>
//           <div className="col-sm-6">
//             <div className="form-group">
//               <label className="d-block">Gender</label>

//               <Field
//                 as="select"
//                 className="form-control player-body-input"
//                 name="gender"
//                 style={{
//                   backgroundColor: "#171919",
//                   color: "white",
//                   height: "52px",
//                 }}
//               >
//                 <option value="">Select Gender</option>
//                 <option value="male">Male</option>
//                 <option value="female">Female</option>
//                 <option value="other">Other</option>
//               </Field>
//               <ErrorMessage
//                 name="contact_persons[0].gender"
//                 component="div"
//                 className="error-message"
//               />
//             </div>
//           </div>
//         </div>

//         <div className="row">
//           <div className="col-sm-6">
//             <div className="form-group">
//               <label className="d-block">Mobile No.</label>
//               <Field
//                 type="text"
//                 className="player-body-input p-4"
//                 name="contact_persons[0].number"
//                 placeholder=""
//               />
//               <ErrorMessage
//                 name="contact_persons[0].number"
//                 component="div"
//                 className="text-danger"
//               />
//             </div>
//           </div>
//           <div className="col-sm-6">
//             <div className="form-group">
//               <label className="d-block">Email ID</label>
//               <Field
//                 type="text"
//                 className="player-body-input p-4"
//                 placeholder=""
//                 name="contact_persons[0].email"
//               />
//               <ErrorMessage
//                 name="contact_persons[0].email"
//                 component="div"
//                 className="text-danger"
//               />
//             </div>
//           </div>
//         </div>

//         <div className="row">
//           <div className="col-sm-6">
//             <div className="form-group">
//               <label className="d-block">Job Position</label>
//               <Field
//                 type="text"
//                 className="player-body-input p-4"
//                 placeholder=""
//                 name="contact_persons[0].position"
//               />
//               <ErrorMessage
//                 name="contact_persons[0].position"
//                 component="div"
//                 className="text-danger"
//               />
//             </div>
//           </div>
//         </div>

//         <div className="experience-add">
//           <p>+ Add More</p>{" "}
//         </div>

//       </div>
//     </div>
//   );
// };

// export default PersonChanges;





import React, { useState, useEffect } from "react";

const PersonChanges = ({ setFieldValue }) => {
  const [contact_persons, setContactPersons] = useState([
    { name: '', gender: '', number: '', email: '', position: '' }
  ]);

  // Whenever contact_persons changes, update the Formik field value
  useEffect(() => {
    setFieldValue('contact_persons', contact_persons);
  }, [contact_persons, setFieldValue]);

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const newContactPersons = [...contact_persons];
    newContactPersons[index][name] = value;
    setContactPersons(newContactPersons);
  };

  const handleAddPerson = () => {
    setContactPersons([...contact_persons, { name: '', gender: '', number: '', email: '', position: '' }]);
  };

  const handleRemovePerson = (index) => {
    const newContactPersons = [...contact_persons];
    newContactPersons.splice(index, 1);
    setContactPersons(newContactPersons);
  };

  return (
    <div>
      <div className="mt-5 d-flex justify-content-center align-items-center">
        <h2 className="progressbar-below-form">Person In charge</h2>
      </div>
      <div className="Form_main_wrap_for_details">
        {contact_persons.map((contact_persons, index) => (
          <div key={index} className="mb-4">
            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <label className="d-block">Full Name</label>
                  <input
                    type="text"
                    className="player-body-input p-4"
                    placeholder=""
                    name="name"
                    value={contact_persons.name}
                    onChange={(e) => handleInputChange(index, e)}
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label className="d-block">Gender</label>
                  <select
                    className="form-control player-body-input"
                    name="gender"
                    value={contact_persons.gender}
                    onChange={(e) => handleInputChange(index, e)}
                    style={{
                      backgroundColor: "#171919",
                      color: "white",
                      height: "52px",
                    }}
                  >
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">Other</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <label className="d-block">Mobile No.</label>
                  <input
                    type="text"
                    className="player-body-input p-4"
                    name="number"
                    placeholder=""
                    value={contact_persons.number}
                    onChange={(e) => handleInputChange(index, e)}
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label className="d-block">Email ID</label>
                  <input
                    type="text"
                    className="player-body-input p-4"
                    placeholder=""
                    name="email"
                    value={contact_persons.email}
                    onChange={(e) => handleInputChange(index, e)}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <label className="d-block">Job Position</label>
                  <input
                    type="text"
                    className="player-body-input p-4"
                    placeholder=""
                    name="position"
                    value={contact_persons.position}
                    onChange={(e) => handleInputChange(index, e)}
                  />
                </div>
              </div>
            </div>

            {index > 0 && (
              <div className="experience-remove mt-4 text-danger">
                <p onClick={() => handleRemovePerson(index)}>Remove Experience</p>
              </div>
            )}
          </div>
        ))}

        <div className="experience-add mt-4">
          <p onClick={handleAddPerson}>+ Add More</p>
        </div>
      </div>
    </div>
  );
};

export default PersonChanges;
