import React, { useState } from "react";
import { Formik, Field } from "formik";
import school_1 from "../../../assets/Group 1000003855.png";
import school_2 from "../../../assets/Group 1000003856.png";
import school_3 from "../../../assets/360_F_238940516_0BihE7YocY9vpgClPDDWuuaLneDwxtWn 1.png";
import school_4 from "../../../assets/1600w-IZPGP0F2HBg 2.png";
import school_5 from "../../../assets/university-college-logo-open-book-symbol-of-knowledge-and-education-university-library-and-school-logotype-template-illustration-vector.png";
import school_6 from "../../../assets/Campion-College-School-Logo-Design-1024x791.png";
import school_7 from "../../../assets/Group 1000003857.png";
import school_8 from "../../../assets/Group 1000003858.png";

const AgentSchoolTab = ({ setFieldValue }) => {
  const [selectedSchool, setSelectedSchool] = useState("");

  const handleSchoolClick = (schoolId) => {
    setSelectedSchool(schoolId);
    setFieldValue("linked_schoolsId", schoolId);
  };

  return (
    <Formik
      initialValues={{ linked_schoolsId: "" }}
      onSubmit={(values) => {
        console.log(values);
        // Handle form submission logic here
      }}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <div className="mt-3 align-items-center">
            <div className="mt-5 d-flex justify-content-center align-items-center">
              <h2 className="progressbar-below-form">
                Are you a part of School?
              </h2>
            </div>
            <div className="mb-5 d-flex justify-content-center align-items-center">
              <input
                className="me-2 club-search"
                type="search"
                placeholder="Search"
                aria-label="Search"
              />
            </div>

            <div className="mb-5">
              <div className="row">
                <div className="col-sm-6 col-md-6 col-lg-3 text-center mb-5">
                  <div
                    className={`school-image ${
                      selectedSchool === "school_1" ? "selected" : ""
                    }`}
                    onClick={() =>
                      handleSchoolClick("66a21fd64ca8e9d805c64659")
                    }
                  >
                    <img
                      src={school_1}
                      alt="School 1"
                      className="image-cursor"
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-3 text-center mb-5">
                  <div
                    className={`school-image ${
                      selectedSchool === "school_2" ? "selected" : ""
                    }`}
                    onClick={() =>
                      handleSchoolClick("66a21fd64ca8e9d805c64659")
                    }
                  >
                    <img
                      src={school_2}
                      alt="School 2"
                      className="image-cursor"
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-3 text-center mb-5">
                  <div
                    className={`school-image ${
                      selectedSchool === "school_3" ? "selected" : ""
                    }`}
                    onClick={() =>
                      handleSchoolClick("66a21fd64ca8e9d805c64659")
                    }
                  >
                    <img
                      src={school_3}
                      alt="School 3"
                      className="image-cursor"
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-3 text-center mb-5">
                  <div
                    className={`school-image ${
                      selectedSchool === "school_4" ? "selected" : ""
                    }`}
                    onClick={() =>
                      handleSchoolClick("66a21fd64ca8e9d805c64659")
                    }
                  >
                    <img
                      src={school_4}
                      alt="School 4"
                      className="image-cursor"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6 col-md-6 col-lg-3 text-center mb-5">
                  <div
                    className={`school-image ${
                      selectedSchool === "school_5" ? "selected" : ""
                    }`}
                    onClick={() =>
                      handleSchoolClick("66a21fd64ca8e9d805c64659")
                    }
                  >
                    <img
                      src={school_5}
                      alt="School 5"
                      className="image-cursor"
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-3 text-center mb-5">
                  <div
                    className={`school-image ${
                      selectedSchool === "school_6" ? "selected" : ""
                    }`}
                    onClick={() =>
                      handleSchoolClick("66a21fd64ca8e9d805c64659")
                    }
                  >
                    <img
                      src={school_6}
                      alt="School 6"
                      className="image-cursor"
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-3 text-center mb-5">
                  <div
                    className={`school-image ${
                      selectedSchool === "school_7" ? "selected" : ""
                    }`}
                    onClick={() =>
                      handleSchoolClick("66a21fd64ca8e9d805c64659")
                    }
                  >
                    <img
                      src={school_7}
                      alt="School 7"
                      className="image-cursor"
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-3 text-center mb-5">
                  <div
                    className={`school-image ${
                      selectedSchool === "school_8" ? "selected" : ""
                    }`}
                    onClick={() =>
                      handleSchoolClick("66a21fd64ca8e9d805c64659")
                    }
                  >
                    <img
                      src={school_8}
                      alt="School 8"
                      className="image-cursor"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default AgentSchoolTab;
