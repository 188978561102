import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import signIn_img from '../../../assets/Rectangle 4337 (1).png';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { loginUser } from '../../../redux/slice/user';

const SignIn = () => {
  const { createUserLoading, createUserError } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const validationSchema = Yup.object({
    email: Yup.string()
      .test('is-valid-email-or-phone', 'Invalid email or mobile number', (value) => {
        const phoneRegex = /^[0-9]{10}$/;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(value) || phoneRegex.test(value);
      })
      .required('Email / Mobile is required'),
    password: Yup.string()
      .min(6, 'Password must be at least 6 characters')
      .required('Password is required'),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    const params = {
      identifier: values.email,
      password: values.password,
    };
  
    // try {
    //   const res = await dispatch(loginUser(params)).unwrap();
      
    //   if (res.isSuccess) {
    //     toast.success('Login successful!');
    //     navigate(`/${res.data.role}`);
    //     localStorage.setItem('loginData', JSON.stringify(res.data)); // Store the login data as a JSON string
    //   } else {
    //     toast.error('Login failed. Please try again.');
    //   }
    // } catch (error) {
    //   if (error.response?.status === 400) {
    //     toast.error('Invalid email and password.');
    //   } else if (error.response?.status === 401) {
    //     toast.error('Incorrect email or password. Please try again.');
    //   } else {
    //     toast.error('Incorrect email or password. Please try again.');
    //   }
    // } finally {
    //   setSubmitting(false);
    // }

    try {
      const res = await dispatch(loginUser(params)).unwrap();
    
      if (res.isSuccess) {
        toast.success('Login successful!');
        
        // Store the login data as a JSON string
        localStorage.setItem('loginData', JSON.stringify(res.data));
        
        // Check if the user has a role
        if (res.data.role) {
          // User has a role, navigate to the respective page
          navigate(`/${res.data.role}`);
        } else {
          // User does not have a role, navigate to the role selection page
          navigate('/SignUp-roles');
        }
      } else {
        toast.error('Login failed. Please try again.');
      }
    } catch (error) {
      if (error.response?.status === 400) {
        toast.error('Invalid email and password.');
      } else if (error.response?.status === 401) {
        toast.error('Incorrect email or password. Please try again.');
      } else {
        toast.error('An unexpected error occurred. Please try again.');
      }
    } finally {
      setSubmitting(false);
    }
    



  };
  
  

  return (
    <div className="row">
      <div className="col-md-12 col-lg-6 justify-content-center">
        <div className="signup justify-content-center width-400">
          <div className="signUp-header-text text-center justify-content-center">
            <span>Enter your details to Sign In</span> 
            <hr className="mx-auto w-50" />
          </div>
          <div className="row justify-content-center">
            <Formik
              initialValues={{ email: '', password: '', remember: false }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting }) => (
                <Form>
                  <div className="mb-4">
                    <Field
                      type="text"
                      name="email"
                      className="signUp-body-input p-4"
                      placeholder="Email / Mobile *"
                    />
                    <ErrorMessage name="email" component="div" className="text-danger" />
                  </div>
                  <div className="mb-3">
                    <Field
                      type="password"
                      name="password"
                      className="signUp-body-input p-4"
                      placeholder="Password *"
                    />
                    <ErrorMessage name="password" component="div" className="text-danger" />
                  </div>
                  <div className="mb-4">
                    <a href="">
                      <span className="text-success">Forgot my password</span>
                    </a>
                  </div>
                  <div className="mb-4 signin-page">
                    <Field type="checkbox" name="remember" />
                    <span className="ml-4 text-white">Remember my details</span>
                  </div>
                  <div className="signUp-button">
                    <button type="submit" disabled={isSubmitting || createUserLoading}>
                      Sign In
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      <div className="col-md-12 col-lg-6 position-relative mt-4">
        <div className="signUp-img">
          <img src={signIn_img} alt="" />
        </div>
        <div className="signUp-img-text justify-content-center">
          <div className="m-3">
            <div className="d-flex align-items-center signUp-text-1">
              <i className="fa-solid fa-period"></i>
              <span className="ml-2">Lorem ipsum dolor sit amet consectetur.</span>
            </div>
            <div className="d-flex align-items-center signUp-text-1">
              <i className="fa-solid fa-period"></i>
              <span className="ml-2">Condimentum integer quis eget vehicula nunc sit tellus.</span>
            </div>
            <div className="d-flex align-items-center signUp-text-1">
              <i className="fa-solid fa-period"></i>
              <span className="ml-2">Id lorem amet ac nullam consequat semper amet magna pulvinar.</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
