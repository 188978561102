
import axios from "axios";
import { BASE_URL } from "../store/baseUrl";

export const loginUserApi = async (params) => {
    try {
        const response = await axios.post(`${BASE_URL}user/login`, params);
        return response;
    } catch (error) {
        // Handle Axios error
        if (axios.isAxiosError(error) && error.response) {
            // Pass on the error response data
            throw error.response;
        } else {
            // Handle other errors (network error, etc.)
            throw { data: { message: error.message } };
        }
    }
};



export const registerApi =async(params)=>{
        try{
             const responce = await axios.post(`${BASE_URL}user/login`, params)
             return responce;
        }catch (error){
                console.log(error)
        }

}



export const createUserApi = async (params) => {
  // console.log('params',params);
  
   
    try {
      const response = await axios.post(`${BASE_URL}User/create`, params);
      
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        throw error.response.data; // Pass on the specific error message from server
      } else {
        throw { message: error.message }; // Handle other errors (network error, etc.)
      }
    }
  };



// ----------player api intrigration--------- //




export const createPlayerApi = async (params) => {
  
  try {
    const response = await axios.post(`${BASE_URL}Player/create`, params, {
      headers: {
        "x-access-token": params.token, // Pass the token in the correct header
      }
    });
    
    return response.data;
  } catch (error) {
    console.error('API Error:', error.response ? error.response.data : error.message); // Debugging
    if (axios.isAxiosError(error) && error.response) {
      throw error.response.data;
    } else {
      throw { message: error.message };
    }
  }
};



// ----------Agent api intrigration--------- //


export const createAgentApi = async (params) => {
  
  
  try {
    const response = await axios.post(`${BASE_URL}Agent/create`, params, {
      headers: {
        "x-access-token": params.token, // Pass the token in the correct header
      }
    });
    
    return response.data;
  } catch (error) {
    console.error('API Error:', error.response ? error.response.data : error.message); // Debugging
    if (axios.isAxiosError(error) && error.response) {
      throw error.response.data;
    } else {
      throw { message: error.message };
    }
  }
};




  // uptodate user api intregrated---------------//




export const updateUserApi = async (params) => {
  const { data, id, token } = params;  
  console.log('data');
  
  try {
    const response = await axios.put(`${BASE_URL}User/update/${id}`, data, {
      headers: {
        "x-access-token": params.token, // Pass the token in the correct header
      }
    });
    
    return response.data;
  } catch (error) {
    console.error('API Error:', error.response ? error.response.data : error.message); // Debugging
    if (axios.isAxiosError(error) && error.response) {
      throw error.response.data;
    } else {
      throw { message: error.message };
    }
  }
};

// ----------School api intrigration--------- //




export const createSchoolApi = async (params) => {
  
  
  try {
    const response = await axios.post(`${BASE_URL}SoccerSchool/create`, params, {
      headers: {
        "x-access-token": params.token, // Pass the token in the correct header
      }
    });
    
    return response.data;
  } catch (error) {
    console.error('API Error:', error.response ? error.response.data : error.message); // Debugging
    if (axios.isAxiosError(error) && error.response) {
      throw error.response.data;
    } else {
      throw { message: error.message };
    }
  }
};


  // ----------Club api intrigration--------- //




export const createClubApi = async (params) => {
  
  try {
    const response = await axios.post(`${BASE_URL}Club/create`, params, {
      headers: {
        "x-access-token": params.token, 
      }
    });
    
    return response.data;
  } catch (error) {
    console.error('API Error:', error.response ? error.response.data : error.message); // Debugging
    if (axios.isAxiosError(error) && error.response) {
      throw error.response.data;
    } else {
      throw { message: error.message };
    }
  }
};

// ----------creat reel api --------- //


// export const createReelApi = async ({userId, token, formData}) => {
//   console.log('formData',formData);
//   console.log('formData',token);
//   console.log('formData',userId);
  
//   try {
//     const response = await axios.post(`${BASE_URL}Reel/uploadReel`,formData,{
//         headers: {
//           'x-accesstoken': token, 
//         },
       
        
        
//         onUploadProgress: (progressEvent) => {
//           const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
//           console.log(`Upload Progress: ${percentCompleted}%`);
//         }
       
//       }
//     );
//     return response.data;
    
//   } catch (error) {
//     if (axios.isAxiosError(error) && error.response) {
//       throw error.response.data;
//     } else {
//       throw { message: error.message };
//     }
//   }
// };




export const createReelApi = async ({userId, token, formData}) => {
  try {
      const response = await axios.post(
          `${BASE_URL}Reel/uploadReel`, 
          formData, 
          {
              headers: {
                  'x-access-token': token, // Ensure 'x-access-token' matches what your API expects
                  'Content-Type': 'multipart/form-data'
              },
              onUploadProgress: (progressEvent) => {
                  const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                  console.log(`Upload Progress: ${percentCompleted}%`);
              }
          }
      );
      return response.data;
  } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
          throw error.response.data;
      } else {
          throw { message: error.message };
      }
  }
};











// ----------GET REEL --------- //



export const getReelApi = async (params) => {
try {
  const response = await axios.get(`${BASE_URL}Reel/getall`);
  return response.data;
} catch (error) {
  if (axios.isAxiosError(error) && error.response) {
    throw new Error(error.response.data.message || 'An error occurred');
  } else {
    throw { message: error.message };
  }
}
};




// ---------- REEL like api --------- //


export const likeReelApi = async (userId, reelId, action) => {
  try {
    const response = await axios.post(`${BASE_URL}Reel/${reelId}/liked`, { user :userId });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      throw new Error(error.response.data.message || 'An error occurred');
    } else {
      throw new Error(error.message);
    }
  }
};

// ---------- REEL like remove  api --------- //

export const likeRemoveApi = async (userId, reelId, action) => {

  try {
    const response = await fetch(`${BASE_URL}Reel/${reelId}/liked`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ user: userId })
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'An error occurred');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};


// ---------- REEL Dislike api --------- //


export const DislikeReelApi = async (userId, reelId, action) => {
  try {
    const response = await axios.post(`${BASE_URL}Reel/${reelId}/dislike`, { user :userId });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      throw new Error(error.response.data.message || 'An error occurred');
    } else {
      throw new Error(error.message);
    }
  }
};

// ---------- REEL Dislike remove api --------- //



export const DislikeRemovelApi = async (userId, reelId, action) => {

  try {
    const response = await fetch(`${BASE_URL}Reel/${reelId}/dislike`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        // You may need to include authorization headers or other headers as required by your API
      },
      body: JSON.stringify({ user: userId })
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'An error occurred');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};




// ----------change password api intrigration--------- //


export const changePasswordApi = async (params) => {
  try {
    const url = `${BASE_URL}User/changePassword/${params.id}`;
    
    const response = await axios.put(url, {
      oldPassword: params.oldPassword,
      newPassword: params.newPassword
    }, {
      headers: {
        'Authorization': `Bearer ${params.token}` // or 'Authorization': `Token ${params.token}` depending on your auth scheme
      }
    });

    console.log(response.data, 'API Response'); 
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      throw error.response.data;
    } else {
      console.error(error.message, 'Unknown Error'); 
      throw { message: error.message };
    }
  }
};

// player upload image api 
const user = localStorage.getItem('loginData')

console.log("useruseruseruseruseruser",user);



export const imageUploadApi = async ({ id, token, formData }) => {
  try {
    const response = await axios.post(`${BASE_URL}Player/${id}/profilePicture`, formData, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'multipart/form-data'
      }
    });
    return response.data;
  } catch (error) {
    console.error('API Error:', error.response ? error.response.data : error.message);
    throw error;
  }
};






// get a profle picture on aplayer 

export const gatPlayerApi = async (userId) => {
  // console.log('userId:', userId);

  try {
    const response = await axios.get(`${BASE_URL}Player/getByUserId/${userId}`);
    return response.data; 
  } catch (error) {
    console.error('API Error:', error.response ? error.response.data : error.message);
    throw error;
  }
};
