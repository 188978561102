import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import homeIcon from "../../assets/side-home.svg";
import reelIcon from "../../assets/side_reels_icon.svg";
import playersIcon from "../../assets/players_icon.svg";
import matchIcon from "../../assets/match_icon.svg";
import negoIcon from "../../assets/nego_icon.svg";
import LogoutIcon from "../../assets/logout-icon.svg";
import "./sidebar.css";
import { toast } from "react-toastify";

const Sidebar = () => {
  const location = useLocation();
  const navigate = useNavigate()
  const role = JSON.parse(localStorage.getItem('loginData')) 
  const rolename= role?.role 
  // console.log(role)
  const activeButton = location.pathname

  const handleLogout =()=>{
     const res= localStorage.removeItem('loginData')
    // console.log("resres",res);
    toast.success('Logout successful!');
    
    navigate('/')
    // window.location.reload();    
  }


  return (
    <div>
      <div className="sidebar_main_wrap">
        <div className="various_content_buttons">
        

<Link  to={`/${rolename}`}>
          <div className={`button_wrap d-flex align-items-center ${activeButton === `/${rolename}` ? "active" : ""}`}>
            <button className="sidebar-btn" >
                <img src={homeIcon} alt="home-icon" />
                Home
            </button>
          </div>
              </Link>

              <Link to={`/${rolename}/player`}>
          <div className={`button_wrap d-flex align-items-center ${activeButton === `/${rolename}/player` ? "active" : ""}`}>
            <button className="sidebar-btn" >
                <img src={playersIcon} alt="players-icon" />
                Players
            </button>
          </div>
              </Link>
              <Link to={`/${rolename}/allmatches`}>
          <div className={`button_wrap d-flex align-items-center ${activeButton === `/${rolename}/allmatches` ? "active" : ""}`}>
            <button className="sidebar-btn">
                <img src={matchIcon} alt="matches-icon" />
                Matches
            </button>
          </div>
              </Link>
              <Link to={`/${rolename}/reels`}>
          <div className={`button_wrap d-flex align-items-center ${activeButton === `/${rolename}/reels` ? "active" : ""}`}>
            <button className="sidebar-btn">
                <img src={reelIcon} alt="reels-icon" /> Reels
            </button>
          </div>
              </Link>
              <Link to={`/${rolename}/Negotiation`}>
          <div className={`button_wrap d-flex align-items-center ${activeButton === `/${rolename}/Negotiation` ? "active" : ""}`}>
            <button className="sidebar-btn">
                <img src={negoIcon} alt="negotiation-icon" />
                Negotiation
            </button>
          </div>
              </Link>
              {/* <Link to={`/Signupin`}>
          <div className="button_wrap login_logout_btn_wrap">
            <button className="sidebar-btn">
                <img src={LogoutIcon} alt="login-icon" />
                Login
            </button>
          </div>
              </Link> */}
              {/* <Link to={`/Signupin`}> */}
          <div className="button_wrap login_logout_btn_wrap">
            <button className="sidebar-btn " onClick={handleLogout} >
                <img src={LogoutIcon} alt="login-icon" />
                Logout 
            </button>
          </div>
              {/* </Link> */}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
