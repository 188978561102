

import React, { useState } from 'react'
import '../style.css'
import brand from "../../assets/Frame 58938.png"
import searchLogo from "../../assets/search.svg"
import headerdp from "../../assets/header-dp.svg"
import ArrowDown from "../../assets/arrow_down.svg"
import { Dropdown } from 'react-bootstrap';

const Simpleheader = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <div>
  <div className='simple-header'>
        {/* <nav className="navbar"> */}
          {/* <div className="logo-section"> */}
            <img src={brand} alt="Logo" />
          {/* </div> */}
          {/* <div className="search-section">
            <img src={searchLogo} alt="" />
            <input type="text" placeholder="Search" />
          </div> */}
          {/* <div className="profile-section d-flex align-items-center">
           
           <div className="header_profile_picture">
            <img src={headerdp} alt=""  />

           </div>

         <Dropdown show={dropdownOpen} onToggle={toggleDropdown}>
              <Dropdown.Toggle 
                as="div" 
                className="profile_info_wrap d-flex align-items-center" 
                id="dropdown-custom-components"
                onClick={toggleDropdown}
              >
                <div>
                  <h6>Craig Torff</h6>
                  <p>Player</p>
                </div>
                <img className='arrow_down' src={ArrowDown} alt="Arrow Down" />
              </Dropdown.Toggle>
              <Dropdown.Menu className="custom-dropdown-menu">
                <Dropdown.Item href="/profile">
                <i class="fa-solid fa-user mr-4"></i> Profile
                </Dropdown.Item>
                <Dropdown.Item href="/setting">
                <i class="fa-solid fa-gear mr-4"></i> Settings
                </Dropdown.Item>
                <Dropdown.Item href="/help">
                <i class="fa-regular fa-circle-question mr-4"></i> Help
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          
          </div> */}
        {/* </nav> */}
        
      </div>
      
    </div>  
  )
 
}

export default Simpleheader




