import React, { useEffect, useState } from "react";
import ReelsIcon from "../../assets/reels-icon.svg";
import VideoIcon from "../../assets/video icon.png";

import { reelsData, videosData } from "../../shared/mock.data";
import { Link, useParams } from "react-router-dom";
import { getReelApi } from "../../redux/api/user";

const VideoSuggestion = () => {
  const { id } = useParams();
  const [loadingReels, setLoadingReels] = useState(true);
  const [reels, setReels] = useState([]);
  const [errorReels, setErrorReels] = useState(null);
  const [loadingVideos, setLoadingVideos] = useState(false); // Initially false since videosData is static
  const [errorVideos, setErrorVideos] = useState(null); // If you have a reason to handle errors for videos
  const videoData = videosData;
  const role = JSON.parse(localStorage.getItem("loginData"));
  const rolename = role?.role;

  // console.log('role', role.role)

  useEffect(() => {
    const fetchReels = async () => {
      try {
        setLoadingReels(true);
        const data = await getReelApi();
        // Filtering the data if id is provided
        const reelData = id
          ? data?.data.filter((reel) => reel.id === id)
          : data?.data;
        setReels(reelData || []);
      } catch (err) {
        setErrorReels(err.message || "Failed to fetch reels.");
      } finally {
        setLoadingReels(false);
      }
    };

    fetchReels();
  }, [id]);

  if (loadingReels && loadingVideos) return <p>Loading...</p>;
  if (errorReels || errorVideos) return <p>{errorReels || errorVideos}</p>;

  return (
    <div>
      <div className="VideoSuggesions_main_wrap">
        {/* Video Section */}
        <div className="For_Videos">
          <div className="reels_top_wrap d-flex gap-12px align-items-center">
            <img src={VideoIcon} alt="" />
            <h6>Videos</h6>
          </div>
          <div className="container">
            <div className="row">
              {videoData.map((item) => (
                <div
                  key={item.id}
                  className="col-12 col-md-6 col-lg-4 col-xl-3 mb-4"
                >
                  <div className="card">
                    <div className="card-body">
                      <div className="card_img_wrap">
                        <img src={item.videocover} alt="" />
                      </div>
                      <div className="match_info_subtitles">
                        <div className="title_wrap d-flex gap-10px">
                          <img src={item.vdotitledp} alt="" />
                          <div>
                            <h5>{item.title}</h5>
                            <h6>{item.profession}</h6>
                            <p>
                              Today <span></span>
                              {item.viewsonvid}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Reels Section */}
        <div className="for_reels">
          <div className="reels_top_wrap d-flex gap-12px align-items-center">
            <img src={ReelsIcon} alt="" />
            <h6>Reels</h6>
          </div>
          <div className="container">
            <div className="row">
              {loadingReels ? (
                <p>Loading reels...</p>
              ) : errorReels ? (
                <p>{errorReels}</p>
              ) : Array.isArray(reels) && reels.length > 0 ? (
                reels.slice(0, 6).map((item, index) => (
                  <div
                    key={index}
                    className="col-12 col-md-6 col-lg-4 col-xl-2 mb-4"
                  >
                    <div className="card">
                      <div className="card-body">
                        <div className="card_img_wrap">
                          <div className="video-player">
                            <Link to={`/${rolename}/reels?reelid=${item._id}`}>
                              <video
                                src={`https://mosis-backend.cradle.services/${item?.video}`}
                                alt={item.title}
                                style={{
                                  width: "100%",
                                  height: "auto",
                                  borderRadius: "10px",
                                }}
                              >
                                Your browser does not support the video tag.
                              </video>
                            </Link>
                          </div>
                        </div>
                        <div className="match_info_subtitles">
                          <h5>{item.title}</h5>
                          <p>
                            Today <span></span>
                            {/* {item.viewsonreel} */}
                            30K
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="d-flex justify-content-center align-items-center">
                  <div className="spinner-border text-white" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoSuggestion;
