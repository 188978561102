import React, { useState } from 'react';
import './SignUp/SignUp.css';
import Signup from './SignUp/Signup';
import SignIn from './SignIn/SignIn';

const SigninSignup = () => {
  const [activeButton, setActiveButton] = useState('signin');

  const handleButtonClick = (button) => {
    setActiveButton(button);
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-12 col-lg-6 text-center">
      <div className="signup-button ">
        <button
          className={activeButton === 'signup' ? 'active' : ''}
          onClick={() => handleButtonClick('signup')}
        >
          Sign Up
        </button>
        <button
          className={activeButton === 'signin' ? 'active' : ''}
          onClick={() => handleButtonClick('signin')}
        >
          Sign In
        </button>
      </div>
        </div>
        <div className="col-md-12 col-lg-6"></div>
      </div>
      {activeButton === 'signup' ? <Signup /> : <SignIn />}
    </div>
  );
};

export default SigninSignup;
