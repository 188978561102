import React, { useEffect, useState } from 'react';
import '../style.css';
import brand from "../../assets/Frame 58938.png";
import searchLogo from "../../assets/search.svg";
import headerdp from "../../assets/header-dp.svg";
import ArrowDown from "../../assets/arrow_down.svg";
import { Dropdown } from 'react-bootstrap';
import agent from '../../assets/Ellipse 148.png';
import School from '../../assets/pngtree-school-logo-design-png-image_6524414 1.png';
import Club from '../../assets/attachment_136553720 2.png';
import { Link } from 'react-router-dom';
import { getPlayerSlice } from '../../redux/slice/GetPlayer';
import { useDispatch } from 'react-redux';

const CustomDropdownItem = ({ to, children, ...props }) => (
  <Dropdown.Item as={Link} to={to} {...props}>
    {children}
  </Dropdown.Item>
);

const Header = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const role = JSON.parse(localStorage.getItem('loginData'));
  const [previewImage, setPreviewImage] = useState(null);
const dispatch = useDispatch()
  const rolename = role?.Name;
  const userRole= role?.role;
  const userId= role?._id;


  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };


  useEffect(() => {
    const getProfilePicture = async () => {
      try {
        const res = await dispatch(getPlayerSlice(userId));        
        if (res.payload.isSuccess && res.payload.data[0]?.face_picture) {
          setPreviewImage(res.payload.data[0].face_picture); // Set profile picture
        }
      } catch (error) {
        console.error("Failed to fetch profile picture:", error);
      }
    };

    if (userId) {
      getProfilePicture();
    }
  }, [userId, dispatch]);




  return (
    <header>
      <nav className="navbar">
        <div className="logo-section text-center">
          <Link to={`/${userRole}/`}>
            <img src={brand} alt="Logo" />
          </Link>
        </div>
        <div className="search-section">
          <img src={searchLogo} alt="Search" />
          <input type="text" placeholder="Search" />
        </div>
        <div className="profile-section d-flex align-items-center">
          <div className="header_profile_picture" show={dropdownOpen}  onClick={toggleDropdown} >
            {userRole === 'player' && (
               <img src={`https://mosis-backend.cradle.services${previewImage}`} alt="Profile" />
            )}
            {userRole === 'agent' && (
              <img src={agent} alt="Agent Profile" />
            )}
            {userRole === 'soccer_school' && (
              <img src={School} alt="School Profile" />
            )}
              {userRole === 'professional_club' && (
              <img src={Club} alt="Club Profile" />
            )}
            {userRole === 'amateur_club' && (
              <img src={Club} alt="Club Profile" />
            )}

          </div>
          <Dropdown show={dropdownOpen} onToggle={toggleDropdown}>
            <Dropdown.Toggle
              as="div"
              className="profile_info_wrap d-flex align-items-center"
              id="dropdown-custom-components"
              onClick={toggleDropdown}
            >
              <div>
                <h6>{rolename}</h6>
                <p>{userRole}</p>
              </div>
              <img className='arrow_down' src={ArrowDown} alt="Arrow Down" />
            </Dropdown.Toggle>
            <Dropdown.Menu className="custom-dropdown-menu">
              <CustomDropdownItem to={`/${userRole}/profile`}>
                <i className="fa-solid fa-user mr-4"></i> My Profile
              </CustomDropdownItem>
              <CustomDropdownItem to={`/${userRole}/setting`}>
                <i className="fa-solid fa-gear mr-4"></i> Settings
              </CustomDropdownItem>
              <CustomDropdownItem to={`/${userRole}/`}>
                <i className="fa-regular fa-circle-question mr-4"></i> Help
              </CustomDropdownItem>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </nav>
    </header>
  );
};

export default Header;
