import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { createAgentApi } from "../api/user";

const initialState = {
  createUser: null,
  createUserLoading: false,
  createUserError: null,



  Agent: {
   
linked_clubsId: "",
linked_schoolsId: "",

Name: "",
cpf: "",

number:  "",

experience: [
  {
    location: "",
    achievements: "",
    startDate: "",
    endDate: "",
    player: ""
  }
],
about: "",
location: "",
dateOfBirth: ""
  }



};





// createAgentApi-------//


export const creatAgentSlice = createAsyncThunk(
  'agent/createAgent',
  async (params, { rejectWithValue }) => {
    // console.log("params params", params)
    try {
      const response = await createAgentApi(params);
      return response;
    } catch (error) {
      return rejectWithValue(error.data || { message: 'An unknown error occurred' });
    }
  }
);




const AgentSlice = createSlice({
  name: 'Agent',
  initialState,
  reducers: {
    changeData: (state, action) => {
      const { name, value } = action.payload;
      // console.log({ name, value }, 'new');
      state.Agent[name] = value;
    }


  },
  extraReducers: (builder) => {
    builder
      .addCase(creatAgentSlice.pending, (state) => {
        state.createUserLoading = true;
        state.createUserError = null;
      })
      .addCase(creatAgentSlice.fulfilled, (state, action) => {
        // console.log("Home action ", action)
        state.createUser = action.payload.data;
        state.createUserLoading = false;
        state.createUserError = null;
      })
      .addCase(creatAgentSlice.rejected, (state, action) => {
        state.createUserError = action.payload;
        state.createUserLoading = false;
      })

  }
});

export const { changeData } = AgentSlice.actions
export default AgentSlice.reducer;
