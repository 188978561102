import React from "react";
import profile1 from "../../assets/Ellipse 166.png";
import myVideo from "../../assets/maxresdefault 2.png";

const MyVideoPage = () => {
  return (
    <div>
      <div className="my-video-page container">
        <div className="v-header">
          <p className="m-title">My Video</p>
        </div>
        <div className="my-page">
          <div>
            <img style={{ width: '68px', height: '68px' }} src={profile1} alt="" />
          </div>
          <div className="myvideo-title">
            <h1>
              Vancouver Whitecaps FC vs. Inter Miami CF | First-Ever Encounter |
              Full Match Highlights
            </h1>
            <p>Soccer - Professional</p>
            <div className="d-flex align-items-center video-view">
              <p className="mb-0">Just Now</p>
              <span className="mx-2">
                <i className="fa-solid fa-period"></i>
              </span>
              <p className="mb-0">100 Views</p>
            </div>
          </div>
          <div className="myvideo-logo">
            <img src={myVideo} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyVideoPage;
