import videoThumbnail from "../assets/match.jpg";
import NewPlayers from "../assets/match.jpg";
import profileimg from "../assets/reels.png";
import PlayerA from "../assets/playera.svg";
import PlayerB from "../assets/playerb.svg";
import PlayerC from "../assets/playerc.svg";
import PlayerD from "../assets/playerd.svg";
import PlayerE from "../assets/playere.svg";
import SideA from "../assets/sidea.svg";
import SideB from "../assets/sideb.svg";
import SideC from "../assets/sidec.svg";
import SideD from "../assets/sided.svg";
import SideE from "../assets/sidee.svg";
import ArrowRight from "../assets/next_arrow.svg";
import VideoTitle from "../assets/video-title.svg";
import VideoCoverA from "../assets/video-covera.svg";
import VideoCoverB from "../assets/video-coverb.svg";
import VideoCoverC from "../assets/video-coverc.svg";
import VideoCoverD from "../assets/video-coverd.svg";
import ReelCoverA from "../assets/reel-covera.svg";
import ReelCoverB from "../assets/reel-coverb.svg";
import ReelCoverC from "../assets/reel-coverc.svg";
import ReelCoverD from "../assets/reel-coverd.svg";
import ReelCoverE from "../assets/reel-covere.svg";
import ReelCoverF from "../assets/reel-coverf.svg";
// import MatchCover from "../assets/Rectangle 4217.png";
import MatchCoverA from "../assets/Rectangle 4217 (1).png";
import MatchCoverB from "../assets/Rectangle 4217.png";
import MatchCoverC from "../assets/Rectangle 4217 (2).png";
import MatchCoverD from "../assets/Rectangle 4217 (1).png";
// import SuggestionAside from "../Home/SuggestionAside"

// REELS DATA
export const reelsData = [
  {
    id: 1,
    reelcover: ReelCoverA,
    title: "Best Goal today!!",
    viewsonreel: '30k',
  },
  {
    id: 2,
    reelcover: ReelCoverB,
    viewsonreel: '30k',
    title: "Best Goal today!!",
  },

  {
    id: 3,
    reelcover: ReelCoverC,
    viewsonreel: '30k',
    title: "Best Goal today!!",
  },
  {
    id: 4,
    reelcover: ReelCoverD,
    viewsonreel: '30k',
    title: "Best Goal today!!",
  },
  {
    id: 5,
    reelcover: ReelCoverE,
    viewsonreel: '30k',
    title: "Best Goal today!!",
  },
  {
    id: 6,
    reelcover: ReelCoverF,
    viewsonreel: '30k',
    title: "Best Goal today!!",
  },
  // Add more objects as needed
];

// VIDEOS DATA
export const videosData = [
  {
    id: 1,
    profession: "Soccer - Professional",
    viewsonvid: "30k Views",
    src: videoThumbnail,
    videocover: VideoCoverA,
    vdotitledp: VideoTitle,
    title: "SFG vs ECSB match _ 20-12-2023 ",
  },
  {
    id: 2,
    profession: "Soccer - Professional",
    viewsonvid: "30k Views",
    vdotitledp: VideoTitle,
    src: videoThumbnail,
    videocover: VideoCoverB,
    title: "SFG vs ECSB match _ 20-12-2023 ",
  },
  {
    id: 3,
    profession: "Soccer - Professional",
    viewsonvid: "30k Views",
    vdotitledp: VideoTitle,
    src: videoThumbnail,
    videocover: VideoCoverC,
    title: "SFG vs ECSB match _ 20-12-2023 ",
  },
  {
    id: 4,
    profession: "Soccer - Professional",
    viewsonvid: "30k Views",
    vdotitledp: VideoTitle,
    src: videoThumbnail,
    videocover: VideoCoverD,
    title: "SFG vs ECSB match _ 20-12-2023 ",
  },
];

// Myvideo 
export const myvideos = [
  {
    id: 1,
    profession: "Updated Today",
    viewsonvid: "View Full Playlist",
    src: videoThumbnail,
    videocover: VideoCoverA,
    vdotitledp: VideoTitle,
    title: "Liked Videos",
  },
  {
    id: 2,
    profession: "Soccer - Professional",
    viewsonvid: "View Full Playlist",
    vdotitledp: VideoTitle,
    src: videoThumbnail,
    videocover: VideoCoverB,
    title: "SFG vs ECSB match _ 20-12-2023 ",
  },
  {
    id: 3,
    profession: "Soccer - Professional",
    viewsonvid: "View Full Playlist",
    vdotitledp: VideoTitle,
    src: videoThumbnail,
    videocover: VideoCoverC,
    title: "SFG vs ECSB match _ 20-12-2023 ",
  },
  {
    id: 4,
    profession: "Soccer - Professional",
    viewsonvid: "View Full Playlist",
    vdotitledp: VideoTitle,
    src: videoThumbnail,
    videocover: VideoCoverD,
    title: "SFG vs ECSB match _ 20-12-2023 ",
  },
  {
    id: 5,
    profession: "Soccer - Professional",
    viewsonvid: "View Full Playlist",
    vdotitledp: VideoTitle,
    src: videoThumbnail,
    videocover: MatchCoverB,
    title: "SFG vs ECSB match _ 20-12-2023 ",
  },
  {
    id: 6,
    profession: "Soccer - Professional",
    viewsonvid: "View Full Playlist",
    vdotitledp: VideoTitle,
    src: videoThumbnail,
    videocover: MatchCoverA,
    title: "SFG vs ECSB match _ 20-12-2023 ",
  },
];


// NEW PLAYERS DATA
export const NewPlayersData = [
  {
    id: 1,
    src: NewPlayers,
    title: "Player Name 01",
    dp: profileimg,
    description: "Offer name here.Some text here...",
    price: "3200$",
  },
  {
    id: 2,
    src: NewPlayers,
    title: "Player Name 02",
    dp: profileimg,
    description: "Offer name here.Some text here...",
    price: "2500$",
  },
  {
    id: 3,
    src: NewPlayers,
    title: "Player Name 03",
    dp: profileimg,
    description: "Offer name here.Some text here...",
    price: "1000$",
  },
  {
    id: 4,
    src: NewPlayers,
    title: "Player Name 04",
    dp: profileimg,
    description: "Offer name here.Some text here...",
    price: "4000$",
  },
  {
    id: 5,
    src: NewPlayers,
    title: "Player Name 05",
    dp: profileimg,
    description: "Offer name here.Some text here...",
    price: "5000$",
  },
  {
    id: 6,
    src: NewPlayers,
    title: "Player Name 06",
    dp: profileimg,
    description: "Offer name here.Some text here...",
    price: "1000$",
  },
  {
    id: 7,
    src: NewPlayers,
    title: "Player Name 07",
    dp: profileimg,
    description: "Offer name here.Some text here...",
    price: "3000$",
  },
  {
    id: 8,
    src: NewPlayers,
    title: "Player Name 08",
    dp: profileimg,
    description: "Offer name here.Some text here...",
    price: "8000$",
  },
];
// NEW PLAYERS DATA ENDS

// PLAYERS LIST DATA

export const PlayersData = [
  {
    id: 1,
    src: PlayerA,
    btn: "Follow",
    title: "Alfonso Bergson",
  },
  {
    id: 2,
    src: PlayerB,
    btn: "Follow",
    title: "Leo Bergson",
  },
  {
    id: 3,
    src: PlayerC,
    btn: "Follow",
    title: "Craig Franci",
  },
  {
    id: 4,
    src: PlayerD,
    btn: "Follow",
    title: "Corey Botosh",
  },
  {
    id: 5,
    src: PlayerE,
    btn: "Follow",
    title: "Cooper Schleifer",
  },
];

// SuggestionAside DATA
export const SuggestedProfiles = [
  {
    id: 1,
    src: "Omar Culhane",
    arrow: ArrowRight,
    img: SideA,
  },
  {
    id: 2,
    src: "Erin Gouse",
    arrow: ArrowRight,
    img: SideB,
  },
  {
    id: 3,
    src: "Hanna Press",
    arrow: ArrowRight,
    img: SideC,
  },
  {
    id: 4,
    src: "Justin George",
    arrow: ArrowRight,
    img: SideD,
  },
  {
    id: 5,
    src: "Kadin Dorwart",
    arrow: ArrowRight,
    img: SideE,
  },
];

// -------player-------- 

export const PlayersProfile = [
  {
    id: 1,
    src: PlayerA,
    btn: "Check",
    title: "Alfonso Bergson",
  },
  {
    id: 2,
    src: PlayerB,
    btn: "Check",
    title: "Leo Bergson",
  },
  {
    id: 3,
    src: PlayerC,
    btn: "Check",
    title: "Craig Franci",
  },
  {
    id: 4,
    src: PlayerD,
    btn: "Check",
    title: "Corey Botosh",
  },
  {
    id: 5,
    src: PlayerE,
    btn: "Check",
    title: "Cooper Schleifer",
  },
  // {
  //   id: 6,
  //   src: PlayerC,
  //   btn: "Check",
  //   title: "Craig Franci",
  // },{
  //   id: 7,
  //   src: PlayerE,
  //   btn: "Check",
  //   title: "Cooper Schleifer",
  // },
  // {
  //   id: 8,
  //   src: PlayerC,
  //   btn: "Check",
  //   title: "Craig Franci",
  // },
  // {
  //   id: 9,
  //   src: PlayerA,
  //   btn: "Check",
  //   title: "Alfonso Bergson",
  // },
  // {
  //   id: 10,
  //   src: PlayerB,
  //   btn: "Check",
  //   title: "Leo Bergson",
  // },

];




// ---------------matches view-----------




export const MatchData = [
  {
    id: 1,
    profession: "Soccer - Professional",
    viewsonvid: "30k Views",
    src: videoThumbnail,
    videocover: VideoCoverA,
    vdotitledp: VideoTitle,
    title: "SFG vs ECSB match _ 20-12-2023 ",
  },
  {
    id: 2,
    profession: "Soccer - Professional",
    viewsonvid: "30k Views",
    vdotitledp: VideoTitle,
    src: videoThumbnail,
    videocover: VideoCoverB,
    title: "SFG vs ECSB match _ 20-12-2023 ",
  },
  {
    id: 3,
    profession: "Soccer - Professional",
    viewsonvid: "30k Views",
    vdotitledp: VideoTitle,
    src: videoThumbnail,
    videocover: VideoCoverC,
    title: "SFG vs ECSB match _ 20-12-2023 ",
  },
  {
    id: 4,
    profession: "Soccer - Professional",
    viewsonvid: "30k Views",
    vdotitledp: VideoTitle,
    src: videoThumbnail,
    videocover: VideoCoverD,
    title: "SFG vs ECSB match _ 20-12-2023 ",
  },
  {
    id: 5,
    profession: "Soccer - Professional",
    viewsonvid: "30k Views",
    vdotitledp: VideoTitle,
    src: videoThumbnail,  
    videocover: MatchCoverA,
    title: "SFG vs ECSB match _ 20-12-2023 ",
  },
  {
    id: 6,
    profession: "Soccer - Professional",
    viewsonvid: "30k Views",
    vdotitledp: VideoTitle,
    src: videoThumbnail,
    videocover: MatchCoverB,
    title: "SFG vs ECSB match _ 20-12-2023 ",
  },
  {
    id: 7,
    profession: "Soccer - Professional",
    viewsonvid: "30k Views",
    src: videoThumbnail,
    videocover: MatchCoverC,
    vdotitledp: VideoTitle,
    title: "SFG vs ECSB match _ 20-12-2023 ",
  },
  {
    id: 8,
    profession: "Soccer - Professional",
    viewsonvid: "30k Views",
    src: videoThumbnail,
    videocover: MatchCoverD,
    vdotitledp: VideoTitle,
    title: "SFG vs ECSB match _ 20-12-2023 ",
  },
  {
    id: 9,
    profession: "Soccer - Professional",
    viewsonvid: "30k Views",
    src: videoThumbnail,
    videocover: VideoCoverA,
    vdotitledp: VideoTitle,
    title: "SFG vs ECSB match _ 20-12-2023 ",
  },
  {
    id: 10,
    profession: "Soccer - Professional",
    viewsonvid: "30k Views",
    vdotitledp: VideoTitle,
    src: videoThumbnail,
    videocover: VideoCoverB,
    title: "SFG vs ECSB match _ 20-12-2023 ",
  },
  {
    id: 11,
    profession: "Soccer - Professional",
    viewsonvid: "30k Views",
    vdotitledp: VideoTitle,
    src: videoThumbnail,
    videocover: VideoCoverC,
    title: "SFG vs ECSB match _ 20-12-2023 ",
  },
  {
    id: 12,
    profession: "Soccer - Professional",
    viewsonvid: "30k Views",
    vdotitledp: VideoTitle,
    src: videoThumbnail,
    videocover: VideoCoverD,
    title: "SFG vs ECSB match _ 20-12-2023 ",
  },
];





export const videoView = [
 
  {
    id: 1,
    profession: "Soccer - Professional",
    viewsonvid: "30k Views",
    vdotitledp: VideoTitle,
    src: videoThumbnail,
    videocover: VideoCoverB,
    title: "SFG vs ECSB match _ 20-12-2023 ",
  },
  {
    id: 2,
    profession: "Soccer - Professional",
    viewsonvid: "30k Views",
    vdotitledp: VideoTitle,
    src: videoThumbnail,
    videocover: VideoCoverC,
    title: "SFG vs ECSB match _ 20-12-2023 ",
  },
  {
    id: 3,
    profession: "Soccer - Professional",
    viewsonvid: "30k Views",
    vdotitledp: VideoTitle,
    src: videoThumbnail,
    videocover: VideoCoverD,
    title: "SFG vs ECSB match _ 20-12-2023 ",
  },
  {
    id: 4,
    profession: "Soccer - Professional",
    viewsonvid: "30k Views",
    src: videoThumbnail,
    videocover: VideoCoverA,
    vdotitledp: VideoTitle,
    title: "SFG vs ECSB match _ 20-12-2023 ",
  },  
];  

// provile reels 

export const profilereelsData = [
  {
    id: 1,
    reelcover: ReelCoverA,
    title: "Best Goal today!!",
    viewsonreel: '30k',
  },
  {
    id: 2,
    reelcover: ReelCoverB,
    viewsonreel: '30k',
    title: "Best Goal today!!",
  },

  {
    id: 3,
    reelcover: ReelCoverC,
    viewsonreel: '30k',
    title: "Best Goal today!!",
  },
  {
    id: 4,
    reelcover: ReelCoverD,
    viewsonreel: '30k',
    title: "Best Goal today!!",
  },
  {
    id: 5,
    reelcover: ReelCoverE,
    viewsonreel: '30k',
    title: "Best Goal today!!",
  },
  {
    id: 6,
    reelcover: ReelCoverF,
    viewsonreel: '30k',
    title: "Best Goal today!!",
  },
  {
    id: 7,
    reelcover: ReelCoverA,
    title: "Best Goal today!!",
    viewsonreel: '30k',
  },
  {
    id: 8,
    reelcover: ReelCoverB,
    viewsonreel: '30k',
    title: "Best Goal today!!",
  },

  {
    id: 9,
    reelcover: ReelCoverC,
    viewsonreel: '30k',
    title: "Best Goal today!!",
  },
  {
    id: 10,
    reelcover: ReelCoverD,
    viewsonreel: '30k',
    title: "Best Goal today!!",
  },
  {
    id: 11,
    reelcover: ReelCoverE,
    viewsonreel: '30k',
    title: "Best Goal today!!",
  },
  {
    id: 12,
    reelcover: ReelCoverF,
    viewsonreel: '30k',
    title: "Best Goal today!!",
  },
  // Add more objects as needed
];

