import React from 'react'
import { PlayersData } from "../../shared/mock.data";
import { useNavigate } from 'react-router-dom';


const Playerslist = () => {

  const navigate = useNavigate();

  const role = JSON.parse(localStorage.getItem('loginData')) 
  const rolename= role?.role 

     const hendleSubmit = () => {
    navigate(`/${rolename}/playerprofile`);
  };

  return (
    <div>
      <div className="players_list_main_wrap mt-5">
      <div className="container">
      <div className="row flex_wrap_wrap">
      {PlayersData.map((item) => (

        <div key={item.id} className="width-202px col-12 mb-4">
          <div className="card custom-card">
            <div className="card-body">
             <img src={item.src} alt="" />
             <h6>{item.title}</h6>
             <button onClick={hendleSubmit}>{item.btn}</button>
            </div>
          </div>
        </div>
      ))}

      </div>
    </div>
      </div>
    </div>
  )
}

export default Playerslist
