import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { createUserApi, loginUserApi, updateUserApi} from "../api/user";

const initialState = {
  createUser: null,
  createUserLoading: false,
  createUserError: null,
};


export const loginUser = createAsyncThunk(
  'user/loginUser',
  async (params, { rejectWithValue }) => {
    try {
      const response = await loginUserApi(params);
      return response.data; 
    } catch (error) {
      // Ensure that the error response is passed correctly
      return rejectWithValue(error.data || { message: 'An unknown error occurred' });
    }
  }
  );

  // createUserSlice /////
  
  export const createUserSlice = createAsyncThunk(
    'user/createUser',
    async (params, { rejectWithValue }) => {
    
      try {
        const response = await createUserApi(params);
        // localStorage.setItem('userSignup',JSON.stringify(response.data))
 

      return response; 
    } catch (error) {
      return rejectWithValue(error.data || { message: 'An unknown error occurred' });
    }
  }
);


// update User-----------//


export const updateUserSlice = createAsyncThunk(
  'user/updateUser',
  async (params, { rejectWithValue }) => {
    // console.log('funtion called')
    // console.log('params',params)
    try {
      const response = await updateUserApi(params);
      localStorage.setItem('loginData',JSON.stringify(response.data))
    return response; 
  } catch (error) {
    return rejectWithValue(error.data || { message: 'An unknown error occurred' });
  }
}
);


 


const UserSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.createUserLoading = true;
        state.createUserError = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        console.log("Home action ", action)
        state.createUser = action.payload;
        state.createUserLoading = false;
        state.createUserError = null;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.createUserError = action.payload;
        state.createUserLoading = false;
      })
      // createUserSlice //
      .addCase(createUserSlice.pending, (state) => {
        state.createUserLoading = true;
        state.createUserError = null;
      })
      .addCase(createUserSlice.fulfilled, (state, action) => {
        console.log("Home action ", action)
        state.createUser = action.payload;
        state.createUserLoading = false;
        state.createUserError = null;
      })
      .addCase(createUserSlice.rejected, (state, action) => {
        state.createUserError = action.payload;
        state.createUserLoading = false;
      })  
      // updateUserSlice ////  
      .addCase(updateUserSlice.pending, (state) => {
        state.createUserLoading = true;
        state.createUserError = null;
      })
      .addCase(updateUserSlice.fulfilled, (state, action) => {
        console.log("Home action ", action)
        state.createUser = action.payload.data;
        state.createUserLoading = false;
        state.createUserError = null;
      })
      .addCase(updateUserSlice.rejected, (state, action) => {
        state.createUserError = action.payload;
        state.createUserLoading = false;
      })  
       
       

  } 
});

export default UserSlice.reducer;
