import React, { useState, useEffect, useRef } from "react";
import { videoView } from "../../shared/mock.data";
import videologo from "../../assets/Rectangle 4250.png";
import profile from "../../assets/Ellipse 165.png";
import profile1 from "../../assets/Ellipse 165 (1).png";
import SmallDp from "../../assets/match_small_dp.svg";
import Commentprofile from "../../assets/Ellipse 166.png";

const Video_save = () => {
  const videoview = videoView;
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isSaveDropdownOpen, setSaveDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const saveDropdownRef = useRef(null);

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
    setSaveDropdownOpen(false); // Close save dropdown when main dropdown opens/closes
  };

  const toggleSaveDropdown = (e) => {
    e.stopPropagation();
    setSaveDropdownOpen(!isSaveDropdownOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
    if (
      saveDropdownRef.current &&
      !saveDropdownRef.current.contains(event.target)
    ) {
      setSaveDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleIconClick = () => {
    // Prevent scrolling to top on icon click
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div>
      <div className="matches_heading">
        <h1>Matches</h1>
      </div>
      <div className="row">
        <div className="col-lg-8">
          <div className="video-image">
            <img src={videologo} alt="" />
          </div>
          <div className="video-text">
            <div className="clubname_options">
              <div className="row_forClub_name row">
                <div className="Club_name col-lg-6 mb-4">
                  <h6>Club name 1 vs Club name 2</h6>
                  <div className="today_views">
                    <p>Today . 30K Views</p>
                  </div>
                  <div className="profile_plus_follow_btn d-flex align-items-center">
                    <div className="d-flex align-items-center gap-2">
                      <img src={SmallDp} alt="" />
                      <span>@brideget</span>
                    </div>
                    <button>Follow</button>
                  </div>
                </div>
                <div className="Various_options col-lg-6">
                  {" "}
                  <div
                    className="d-flex justify-content-between like_share_save_etc_options like"
                    ref={dropdownRef}
                  >
                    <div>
                      <i className="fa-solid fa-comment-medical fa-lg"></i>
                      <p>Comments</p>
                    </div>
                    <div>
                      <i className="fa-solid fa-thumbs-up fa-lg"></i>
                      <p>Like</p>
                    </div>
                    <div>
                      <i className="fa-solid fa-thumbs-down fa-lg"></i>
                      <p>Dislike</p>
                    </div>
                    <div>
                      <i className="fa-solid fa-share fa-lg"></i>
                      <p>Share</p>
                    </div>
                    <div className="position-relative">
                      <i
                        className="fa-solid fa-bookmark fa-lg"
                        onClick={toggleDropdown}
                      ></i>
                      <p>Save</p>
                      {isDropdownOpen && (
                        <div className="custom-dropdown">
                          <a
                            className="custom-dropdown-item"
                            href="#"
                            onClick={toggleSaveDropdown}
                          >
                            <i className="fa-solid fa-bookmark fa-xs"></i>
                            <span> Save to playlist</span>
                          </a>
                          <a className="custom-dropdown-item" href="#">
                            <i className="fa-solid fa-download"></i>{" "}
                            <span> Download</span>
                          </a>
                          <a className="custom-dropdown-item" href="#">
                            <i className="fa-solid fa-share"></i>
                            <span>Share</span>
                          </a>
                          <a className="custom-dropdown-item" href="#">
                            <i className="fa-solid fa-triangle-exclamation"></i>
                            <span>Report </span>
                          </a>
                          {isSaveDropdownOpen && (
                            <div
                              className="dropdown-for-save dropdown_for_save show"
                              onClick={(e) => e.stopPropagation()}
                              ref={saveDropdownRef}
                            >
                              <p className="dropdown-header">Save to..</p>
                              <div className="padding-l-r-18">
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="watchLater"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="watchLater"
                                  >
                                    Watch Later
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="tutorial"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="tutorial"
                                  >
                                    Tutorial
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="workout"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="workout"
                                  >
                                    Workout
                                  </label>
                                </div>
                              </div>
                              <div className="dropdown-footer">
                                <i className="fa-solid fa-plus"></i>
                                <span>Create new playlist</span>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                    <div>
                      <i className="fa-solid fa-ellipsis-vertical fa-lg"></i>
                      <p>Option</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="comment-section">
            <div className="section-1">
              <div className="text-1">
                <h1>Comments</h1>
                <p>100 Comments</p>
              </div>
              <div className="d-flex first-div blocked_767">
                <div className="">
                  <div className="d-flex  c-profile">
                    <img src={Commentprofile} alt="" />
                    <p>@craig</p>
                  </div>
                  <p className="comment-input">
                    <input
                      type="text"
                      name=""
                      id=""
                      placeholder="Lorem ipsum dolor sit amet consectetur. Consectetur aliquet senectus dictum habitant donec augue accumsan."
                    />
                  </p>
                </div>
                <div className="comment_cancel_btns">
                  <button className="btn-1" onClick={handleIconClick}>
                    Comment
                  </button>
                  <button className="btn-2" onClick={handleIconClick}>
                    Cancel
                  </button>
                </div>
              </div>
            </div>
            <hr className="line" />
            <div className="section-1 section-2">
              <div className="d-flex first-div">
                <div className="">
                  <div className="d-flex  c-profile">
                    <img src={Commentprofile} alt="" />
                    <p>@craig</p>
                    <span> 4 hours ago</span>
                  </div>
                  <p className="comment-input">
                    <input
                      type="text"
                      name=""
                      id=""
                      placeholder="Lorem ipsum dolor sit amet consectetur. Consectetur aliquet senectus dictum habitant donec augue accumsan."
                    />
                  </p>
                </div>
                <div>
                  <div className="c-1">
                    <i class="fa-solid fa-ellipsis-vertical"></i>
                  </div>
                </div>
              </div>
              <div className="lik-unlike d-flex  like_share_save_etc_options">
                <div>
                  <i className="fa-solid fa-thumbs-up"></i>
                  <p>Like</p>
                </div>
                <div>
                  <i className="fa-solid fa-thumbs-down"></i>
                  <p>Dislike</p>
                </div>
                <div>
                  <p className="reply">Reply</p>
                </div>
              </div>
            </div>
            <div className="section-1 section-2">
              <div className="d-flex first-div">
                <div className="">
                  <div className="d-flex  c-profile">
                    <img src={Commentprofile} alt="" />
                    <p>@craig</p>
                    <span> 4 hours ago</span>
                  </div>
                  <p className="comment-input">
                    <input
                      type="text"
                      name=""
                      id=""
                      placeholder="Lorem ipsum dolor sit amet consectetur. Consectetur aliquet senectus dictum habitant donec augue accumsan."
                    />
                  </p>
                </div>
                <div>
                  <div className="c-1">
                    <i class="fa-solid fa-ellipsis-vertical"></i>
                  </div>
                </div>
              </div>
              <div className="lik-unlike d-flex  like_share_save_etc_options">
                <div>
                  <i className="fa-solid fa-thumbs-up"></i>
                  <p>Like</p>
                </div>
                <div>
                  <i className="fa-solid fa-thumbs-down"></i>
                  <p>Dislike</p>
                </div>
                <div>
                  <p className="reply">Reply</p>
                </div>
              </div>
            </div>
            <div className="section-1 section-2">
              <div className="d-flex first-div">
                <div className="">
                  <div className="d-flex  c-profile">
                    <img src={Commentprofile} alt="" />
                    <p>@craig</p>
                    <span> 4 hours ago</span>
                  </div>
                  <p className="comment-input">
                    <input
                      type="text"
                      name=""
                      id=""
                      placeholder="Lorem ipsum dolor sit amet consectetur. Consectetur aliquet senectus dictum habitant donec augue accumsan."
                    />
                  </p>
                </div>
                <div>
                  <div className="c-1">
                    <i class="fa-solid fa-ellipsis-vertical"></i>
                  </div>
                </div>
              </div>
              <div className="lik-unlike d-flex  like_share_save_etc_options">
                <div>
                  <i className="fa-solid fa-thumbs-up"></i>
                  <p>Like</p>
                </div>
                <div>
                  <i className="fa-solid fa-thumbs-down"></i>
                  <p>Dislike</p>
                </div>
                <div>
                  <p className="reply">Reply</p>
                </div>
              </div>
            </div>
            <div className="section-1 section-2">
              <div className="d-flex first-div">
                <div className="">
                  <div className="d-flex  c-profile">
                    <img src={Commentprofile} alt="" />
                    <p>@craig</p>
                    <span> 4 hours ago</span>
                  </div>
                  <p className="comment-input">
                    <input
                      type="text"
                      name=""
                      id=""
                      placeholder="Write your comment here"
                    />
                  </p>
                </div>
                <div>
                  <div className="c-1">
                    <i class="fa-solid fa-ellipsis-vertical"></i>
                  </div>
                </div>
              </div>
              <div className="lik-unlike d-flex  like_share_save_etc_options">
                <div>
                  <i className="fa-solid fa-thumbs-up"></i>
                  <p>Like</p>
                </div>
                <div>
                  <i className="fa-solid fa-thumbs-down"></i>
                  <p>Dislike</p>
                </div>
                <div>
                  <p className="reply">Reply</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="VideoSuggesions_main_wrap">
            <div className="For_Videos">
              <div className="container">
                <div className="row d-block">
                  {videoview.map((item) => (
                    <div key={item.id}>
                      <div className="card">
                        <div className="card-body">
                          <div className="card_img_wrap">
                            <img src={item.videocover} alt="" />
                          </div>
                          <div className="match_info_subtitles">
                            <div className="title_wrap d-flex gap-10px">
                              <img src={item.vdotitledp} alt="" />
                              <div>
                                <h5>{item.title}</h5>
                                <h6>{item.profession}</h6>
                                <p>
                                  Today <span></span>
                                  {item.viewsonvid}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Video_save;
