
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { createReelApi } from "../api/user";

// Initial state
const initialState = {
  reelData: null,  // Renamed to reflect the purpose
  loading: false,  // Renamed to be more general
  error: null,
};

// Create reel thunk
export const createReelSlices = createAsyncThunk(
  'reel/createReel',  // Updated action type to be more descriptive
  async (params, { rejectWithValue }) => {
    try {
      const response = await createReelApi(params);
      // Ensure that the response structure is correct
      return response.data; // Assuming `response.data` contains the relevant data
    } catch (error) {
      // Ensure error structure is correctly handled
      return rejectWithValue(error.response?.data || { message: 'An unknown error occurred' });
    }
  }
);

// Create reel slice
const reelSlice = createSlice({
  name: 'reel',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createReelSlices.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createReelSlices.fulfilled, (state, action) => {
        state.reelData = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(createReelSlices.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      });
  }
});

export default reelSlice.reducer;
