import React from 'react'
import filter from '../../assets/Vector (1).png'
import reels from '../../assets/Rectangle 4219.png'



const MyReels = () => {

  const role = JSON.parse(localStorage.getItem('loginData')) 
  const rolename= role?.role 

  return (
    <>
    <div className='main-div'>
<div style={{color: 'white'}}>playList &gt; <span style={{color: '#0CF1A1'}}>My Reels</span></div>
    <div className="filter-btn d-flex justify-content-end my-reels mt-2">     
        <button className=" align-items-center">
          <img src={filter} alt="" />
          <span>Filter</span>
        </button>
  </div>

  <div className="my-reels row">
    <div className="my-reel-header col-12">
        <div className="header-content">
            <div className="header1 col-4">
        <div className='d-flex myreel-img'>
            <div>.</div>
        <span>Reels</span>
        </div>
        </div>
        <div className="header2 col-8">
        <div><span>Visibility</span></div>
        <div><span>Date</span></div>
        <div><span>Views</span></div>
        <div><span>Likes</span></div>
        <div><span>Dislikes</span></div>
        </div>
        </div>
    </div>
  </div>
  {/* view reels */}
    <div className="my-reel-body col-12">
        <div className="header-content">
            <div className="header1 col-4">
        <div className='d-flex myreel-img'>
          <a href={`/${rolename}/reeldetail`}>
           <img src={reels} alt="" />
           </a>
           <span>SFG vs ECSB match _ 20-12-2023 </span>
          
        </div>
        </div>
        <div className="body2 col-8 aline-items-center">
        <div><span>Public</span></div>
        <div><span>20-12-2023 </span></div>
        <div><span>500</span></div>
        <div><span>200</span></div>
        <div><span>50</span></div>
        </div>
        </div>
  </div>
    {/* view reels */}
  <div className="my-reel-body col-12">
        <div className="header-content">
            <div className="header1 col-4">
        <div className='d-flex myreel-img'>
        <a href={`/${rolename}/reeldetail`}>
           <img src={reels} alt="" />
           </a>
           <span>SFG vs ECSB match _ 20-12-2023 </span>
        </div>
        </div>
        <div className="body2 col-8 aline-items-center">
        <div><span>Public</span></div>
        <div><span>20-12-2023 </span></div>
        <div><span>500</span></div>
        <div><span>200</span></div>
        <div><span>50</span></div>
        </div>
        </div>
  </div>
   {/* view reels */}
   <div className="my-reel-body col-12">
        <div className="header-content">
            <div className="header1 col-4">
        <div className='d-flex myreel-img'>
        <a href={`/${rolename}/reeldetail`}>
           <img src={reels} alt="" />
           </a>
           <span>SFG vs ECSB match _ 20-12-2023 </span>
        </div>
        </div>
        <div className="body2 col-8 aline-items-center">
        <div><span>Public</span></div>
        <div><span>20-12-2023 </span></div>
        <div><span>500</span></div>
        <div><span>200</span></div>
        <div><span>50</span></div>
        </div>
        </div>
  </div>
   {/* view reels */}
   <div className="my-reel-body col-12">
        <div className="header-content">
            <div className="header1 col-4">
        <div className='d-flex myreel-img'>
        <a href={`/${rolename}/reeldetail`}>
           <img src={reels} alt="" />
           </a>
           <span>SFG vs ECSB match _ 20-12-2023 </span>
        </div>
        </div>
        <div className="body2 col-8 aline-items-center">
        <div><span>Public</span></div>
        <div><span>20-12-2023 </span></div>
        <div><span>500</span></div>
        <div><span>200</span></div>
        <div><span>50</span></div>
        </div>
        </div>
  </div>
 

  <div className="mt-5 text-center">
  <nav aria-label="Page">
    <ul className="pagination justify-content-center">
      <li className="">
        <a className="pagelink" href="#">
          <span aria-hidden="true">Previous</span>
        </a>
      </li>
      <li className="p-1 ml-2 ">
        <a className="text-white" href="#">
          1
        </a>
      </li>
      <li className="p-2 ml-2 justify-content-center">
        <a className="" href="#">
          2
        </a>
      </li>
      <li className="p-2 ml-2">
        <a className="" href="#">
          3
        </a>
      </li>
      <li className="">
        <a className="pagelink" href="#" aria-label="Next">
          <span aria-hidden="true">Next</span>
        </a>
      </li>
    </ul>
  </nav>
</div>



    </div>
    </>
  )
}

export default MyReels