import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {  gatPlayerApi } from '../api/user';



export const getPlayerSlice = createAsyncThunk(
    'get/player',
    async (params, { rejectWithValue }) => {
      // console.log("params params", params)
      try {
        const response = await gatPlayerApi(params);
        return response;
      } catch (error) {
        return rejectWithValue(error.data || { message: 'An unknown error occurred' });
      }
    }
  );
  
  




// Profile slice
const profileSlice = createSlice({
  name: 'profile',
  initialState: {
    face_picture: null, 
    loading: false,
    player:{},
    error: null, 
  },
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPlayerSlice.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getPlayerSlice.fulfilled, (state, action) => {
        state.loading = false;
        state.face_picture = action.payload.face_picture;
        // console.log(action.payload?.data[0]);
        
        state.player = action.payload?.data[0]
      })
      .addCase(getPlayerSlice.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to fetch profile picture';
      });
  },
});


export default profileSlice.reducer;
