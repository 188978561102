  import * as React from "react";
  import * as ReactDOM from "react-dom/client";
  import 'bootstrap/dist/css/bootstrap.min.css';

  import {
    createBrowserRouter,
    RouterProvider,
    Routes,
  } from "react-router-dom";
  import "./index.css";
  import './styles/style.scss'
  import { Indexrouter } from "./Router/defaultsRoute";
  import { SimpleRoute } from "./Router/SimpleRoutes";
  // import { PlayerRoute } from "./Router/PlayerRoute";

  import { ToastContainer } from "react-toastify";
  import { Provider } from "react-redux";
  import { store } from "./redux/store/store";
import { PlayerRoute } from "./Router/PlayerRoute";
import { landingPageRoute } from "./Router/LandingPage";
import { AgentRoute } from "./Router/AgentRoute";
import { SchoolRoute } from "./Router/School";
import { Amateur_club } from "./Router/Amateur_club";
import { Professional_club } from "./Router/professional_club";



  const router = createBrowserRouter([
    ...Indexrouter,
    ...landingPageRoute,
    ...SimpleRoute,
    ...PlayerRoute ,
    ...AgentRoute,
    ...SchoolRoute,
    ...Amateur_club,
    ...Professional_club
     




  ]);

  ReactDOM.createRoot(document.getElementById("root")).render(
    <React.StrictMode>
      <ToastContainer />
      <Provider store={store}>
        <RouterProvider router={router}>
          <Routes/>
        </RouterProvider>
      </Provider>
    </React.StrictMode>

  )

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
