import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../SignUpRole/SignUp_role.css';
import player from '../../../assets/Vector (2).png';
import Agent from '../../../assets/ic_baseline-support-agent.png';
import school from '../../../assets/Vector (3).png';
import club_1 from '../../../assets/mingcute_group-3-fill.png';
import club_2 from '../../../assets/Group.png';
import Divider from '../../../assets/divider-row.svg';
import { toast } from 'react-toastify';

const SignUp_Role = () => {
  const [selectedRole, setSelectedRole] = useState('');
  const navigate = useNavigate();



  const handleRoleSelect = (role) => {
   
       
         setSelectedRole(role)
        };

  const handleNextClick = () => {
    if (!selectedRole) {
      toast.warn('Please select a role');
      return;
    }

    localStorage.setItem('UserRole', selectedRole.toString());
    navigate(`/role/${selectedRole}`);
  };

  return (
    <div className="container">
      <div className='row'>
        <div className="role-header col-12">
          <h1>Help us understand you a bit more!!</h1>
          <div className="divider_row text-center">
            <img src={Divider} alt="hr line" />
          </div>
        </div>
        <div className="role-body col-12">
          <div className="role-body-text">
            <h2>You will be using our platform as..? <span className='red-star'>*</span></h2>
          </div>
          <div className="all-role">
            <div className="mt-5 d-flex justify-content-around flex-wrap-wrap">
              <div className={`role-1 ${selectedRole === 'player' ? 'selected' : ''}`}>
                <button onClick={() => handleRoleSelect('player')} className='justify-content-around'>
                  <img src={player} alt="Player" />
                  <span>Player</span>
                </button>
              </div>
              <div className={`role-1 ${selectedRole === 'agent' ? 'selected' : ''}`}>
                <button onClick={() => handleRoleSelect('agent')} className='justify-content-around'>
                  <img src={Agent} alt="Agent" />
                  <span>Agent</span>
                </button>
              </div>
              <div className={`role-1 ${selectedRole === 'soccer_school' ? 'selected' : ''}`}>
                <button onClick={() => handleRoleSelect('soccer_school')} className='justify-content-around'>
                  <img src={school} alt="School" />
                  <span>School</span>
                </button>
              </div>
              <div className={`role-1 mx-3 ${selectedRole === 'professional_club' ? 'selected' : ''}`}>
                <button onClick={() => handleRoleSelect('professional_club')} className='justify-content-around'>
                  <img src={club_1} alt="Professional Club" />
                  <span>Professional Club</span>
                </button>
              </div>
              <div className={`role-1 mx-3 ${selectedRole === 'amateur_club' ? 'selected' : ''}`}>
                <button onClick={() => handleRoleSelect('amateur_club')} className='justify-content-around'>
                  <img src={club_2} alt="Amateur Club" />
                  <span>Amateur Club</span>
                </button> 
              </div>
            </div>
          </div>
          <div className='d-flex justify-content-center role-next-button'>
            <button onClick={handleNextClick}>Sign Up</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp_Role;
