import Landing from "../components/Landing/Landing";
import Pricing from "../components/Landing/Pricing";



 export const landingPageRoute = [{
    
  path: "/",
//   element: <SimpleLayout/>,
  children: [
    // login / register page 
   
   
    { path: '/', element: <Landing /> },
    { path: 'Pricing', element: <Pricing /> },
  ]
}
   
  ];