import Home from "../pages/Home";
import AllMatches from "../pages/Matches/AllMatches";
import Matches from "../pages/Matches/Matches";
import Video_save from "../pages/Matches/Video_save";
import Negotiation from "../pages/Negotiation/Negotiation";
import PlayerProfile from "../pages/Players/PlayerProfile";
import Players from "../pages/Players/Players";
import MyReels from "../pages/Profile/MyReels";
import PlaylistView from "../pages/Profile/PlaylistView";
import Profile from "../pages/Profile/Profile";
import Reeldetail from "../pages/Profile/Reeldetail";
import Settings from "../pages/Setting/Settings";
import Reels from "../pages/homeComponents/Reels";
import Offers from "../pages/homeComponents/offers";
import Landing from "../components/Landing/Landing";
import ProtectedRoutes from "../protectedRoutes";
import Layout from "./defaultLayout/Layout";

 export const Indexrouter = [
    {
      path: "/",
      // element: <Layout/>,
      children: [
        // {path: "/Home",element: <Home/>},
        // {path: "offers",element: <Offers/>},
        // { path: 'matches', element: <Matches /> },
        // { path: 'reels', element: <Reels /> },
        // { path: 'player', element: <Players /> },
        // { path: 'playerprofile', element: <PlayerProfile /> },
        // { path: 'allmatches', element: <AllMatches /> },
        // { path: 'video-save', element: <Video_save /> },
        // { path: 'Negotiation', element: <Negotiation /> },
        // {
        //   path: 'profile',
        //   element: (
        //     <ProtectedRoutes>
        //       <Profile />
        //     </ProtectedRoutes>
        //   ),
        // },
        // { path: 'myreels', element: <MyReels /> },
        // { path: 'playlistView', element: <PlaylistView /> },
        // { path: 'reeldetail', element: <Reeldetail /> },
        // { path: 'setting', element: <Settings /> },
        { path: '/', element: <Landing /> },

      ],
    
    },
   
  ];


  
  
//       ],
    
//     },

//   ];