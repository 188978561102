import React, { useEffect, useState } from "react";
import "./Profile.css";
import profile from "../../assets/unsplash_6anudmpILw4.png";
import { myvideos, reelsData } from "../../shared/mock.data";
import ReelsIcon from "../../assets/reels-icon.svg";
import Player from "../Players/Player";
import GeoChart from "./GeoChart";
import ProfileViewChart from "./ProfileViewChart";
import Negotiations from "../../assets/Group 1000003743 (1).png";
import pv_1 from "../../assets/Ellipse 192.png";

import pv_2 from "../../assets/Ellipse 193.png";
import pv_3 from "../../assets/Ellipse 194.png";
import pv_4 from "../../assets/Ellipse 195.png";
import RoundQMark from "../../assets/round_q_mark.svg";
import MarcusDp from "../../assets/Marcus_dp.svg";
import ActionEdit from "../../assets/action_edit.svg";
import DeleteIcon from "../../assets/delete_icon.svg";
import ModalXIcon from "../../assets/ph_x-bold.svg";
import DummyDp from "../../assets/dummy_dp.svg";
import ClubsGroup from "../../assets/clubs_group.svg";
import ArrowUp from "../../assets/Arrow_up.svg";
import MingCute from "../../assets/mingcute_user-follow-fill.svg";

import { Dropdown, DropdownButton, Form } from "react-bootstrap";
import { getPlayerSlice } from "../../redux/slice/GetPlayer";
import { useDispatch, useSelector } from "react-redux";

const loginData = localStorage.getItem("loginData");

let role = {}; // Default value if parsing fails
if (loginData && loginData !== 'undefined') {
  try {
    role = JSON.parse(loginData);
  } catch (error) {
    console.error('Error parsing JSON:', error);
  }
} else {
  console.warn('loginData is not available or is undefined');
}

// console.log('Role:', role);


const rolename = role?.role;
const userName = role?.Name;
const userId = role?._id;



const Profile = () => {

  const [activeSection, setActiveSection] = useState("Account");
  const [previewImage, setPreviewImage] = useState(null);
  const dispatch = useDispatch()

  const renderSection = () => {
    switch (activeSection) {
      case "Account":
        return <AccountSection />;
      case "Playlist":
        return <PlaylistSection />;
      case "Analytics":
        return <AnalyticsSection />;
      case "Access":
        return <AccessSection />;
      default:
        return <AccountSection />;
    }
  };

  const { player, loading, error } = useSelector((state) => state.profile);

  useEffect(() => {
    const getProfilePicture = async () => {
      try {
        const res = await dispatch(getPlayerSlice(userId));
        // console.log('res',res);
        
        if (res.payload.isSuccess && res.payload.data[0]?.face_picture) {
          setPreviewImage(res.payload.data[0].face_picture); // Set profile picture
        }
      } catch (error) {
        console.error("Failed to fetch profile picture:", error);
      }
    };

    if (userId) {
      getProfilePicture();
    }
  }, [userId, dispatch]);

console.log('playerplayerplayer',player);

const playerName= player?.Name   
const playerposition= player?.experience[0]?.position
const playertshirtNumber= player?.experience[0]?.tshirtNumber



  return (
    <div>
      <div>
        <div className="profile gap_99 row">
          <div className="profile_main_head">
            <h1>My Profile</h1>
          </div>
          <div className="profile_top_two_cards_wrap">
            <div className="row">
              <div className="col-xl-6">
                <div className="profile_top_box_wrap">
                  <div className="edit_icon">
                    <i className="fa-regular fa-pen-to-square"></i>
                  </div>
                  <div className="user_dp_and_info d-flex align-items-center gap-5">
                    <div className="user_dp">
                    <img src={`https://mosis-backend.cradle.services${previewImage}`} alt="Profile" />
                    </div>
                    <div className="user_info_wrap">
                      <div className="name_btn_wrap d-flex align-items-center justify-content-between">
                        <h2>{playerName}</h2>
                        <button>Under Negotiation</button>
                      </div>
                      <div className="profile-detail2 d-flex gap-2 align-items-center">
                        <span>{rolename}</span>
                        <span>.</span>
                        <span>{playertshirtNumber}</span>
                        <span>.</span>
                        <span>{playerposition}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-6">
                <div className="profile_top_box_wrap py-5">
                  <div className="reels-view d-flex justify-content-between gap-3">
                    <div className="text-center">
                      <span>35</span>
                      <p>Total Reels</p>
                    </div>
                    <div className="text-center">
                      <span>100</span>
                      <p>Profile Views</p>
                    </div>
                    <div className="text-center">
                      <span>2503</span>
                      <p>Total Reels Views</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="moreOption d-flex">
        <span
          onClick={() => setActiveSection("Account")}
          style={{
            color: activeSection === "Account" ? "#9AE8F8" : "inherit",
            textDecoration: activeSection === "Account" ? "underline" : "none",
          }}
        >
          Account
        </span>
        <span
          onClick={() => setActiveSection("Playlist")}
          style={{
            color: activeSection === "Playlist" ? "#9AE8F8" : "inherit",
            textDecoration: activeSection === "Playlist" ? "underline" : "none",
          }}
        >
          Playlist
        </span>
        <span
          onClick={() => setActiveSection("Analytics")}
          style={{
            color: activeSection === "Analytics" ? "#9AE8F8" : "inherit",
            textDecoration:
              activeSection === "Analytics" ? "underline" : "none",
          }}
        >
          Analytics
        </span>
        {(rolename === "soccer_school" || rolename === "club") && (
        <span
          onClick={() => setActiveSection("Access")}
          style={{
            color: activeSection === "Access" ? "#9AE8F8" : "inherit",
            textDecoration: activeSection === "Access" ? "underline" : "none",
          }}
        >
          Access
        </span>
)}
      </div>

      <div className="section-content">{renderSection()}</div>
    </div>
  );
};

const AccountSection = () => (
  <div>
    <div className="Account_overview_height_weight_box_wrap">
      <div className="mb-77px">
      <div className="d-flex mb-4">
      <div className="section_for_row box_wrap-section-1">
        <h3><li>Overview:</li></h3>
      </div>
      <div className="section_for_row box_wrap-section-2">
        {" "}
        <p>2021 - Present:</p>
      </div>
      <div className="section_for_row box_wrap-section-3">
        {" "}
        <p>
          Lorem ipsum dolor sit amet consectetur. Imperdiet urna aliquet
          pellentesque vulputate nisi.
        </p>
      </div>
      </div>
      <div className="d-flex">
      <div className="section_for_row box_wrap-section-1">
        {/* <h3>Overview:</h3> */}
      </div>
      <div className="section_for_row box_wrap-section-2">
        {" "}
        <p>2015 - 2021:</p>
      </div>
      <div className="section_for_row box_wrap-section-3">
        {" "}
        <p>
          Lorem ipsum dolor sit amet consectetur. Imperdiet urna aliquet
          pellentesque vulputate nisi.
        </p>
      </div>
      </div>
      </div>
      <div className="d-flex mb-4">
      <div className="section_for_row box_wrap-section-1">
        <h3><li>Height:</li></h3>
      </div>
      <div className="section_for_row box_wrap-section-2">
        {" "}
        <p>182 cm</p>
      </div>
      <div className="section_for_row box_wrap-section-3">
        {" "}
        <div className="section_for_row box_wrap-section-1 d-flex align-items-center  gap-3 justify-content-between width-unset">
        <h3><li>Clubs:</li></h3>
        <img src={ClubsGroup} alt="" />
      </div>
      
      </div>
      </div>
      <div className="d-flex">
      <div className="section_for_row box_wrap-section-1">
      <h3><li>Weight:</li></h3>
      </div>
      <div className="section_for_row box_wrap-section-2">
        {" "}
        <p>75 kg</p>
      </div>
      <div className="section_for_row box_wrap-section-3">
        {" "}
        <div className="section_for_row box_wrap-section-1 d-flex align-items-center justify-content-between">
        <h3><li>Profile Visits:</li></h3>
        <div className="d-flex align-items-center gap-5">
        <p>1000</p>
        <span>21% <img src={ArrowUp} alt="" /></span>
        </div>
      </div>
      </div>
      </div>
    </div>
    <div className="VideoSuggesions_main_wrap">
      <div className="for_reels">
        <div className="reels_top_wrap d-flex justify-content-between align-items-center">
         
          <h6> <img src={ReelsIcon} alt="" /> My Reels</h6>
          <p>+ View More</p>
        </div>
        <div className="container">
          <div className="row">
            {reelsData.map((item) => (
              <div
                key={item.id}
                className="col-12 col-md-6 col-lg-4 col-xl-2 mb-4"
              >
                <div className="card">
                  <div className="card-body">
                    <div className="card_img_wrap">
                      <div className="video-player">
                        <a href={`/${rolename}/myreels`}>
                          <img src={item.reelcover} alt="" />
                        </a>
                      </div>
                    </div>
                    <div className="match_info_subtitles">
                      <h5>{item.title}</h5>
                      <p>
                        Today <span></span>
                        {item.viewsonreel}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
    <div className="reels_top_wrap d-flex justify-content-between align-items-center">
         
         <h6> <img src={MingCute} alt="" /> My Connections</h6>
         <p>+ View More</p>
       </div>
    <Player />
    <Player />
  </div>
);

const PlaylistSection = () => (
  <div>
    <div className="mt-4">
      <DropdownButton
        id="dropdown-custom-button"
        title={
          <span>
            Recently Added <i class="fa-solid fa-chevron-down ml-2"></i>
          </span>
        }
      >
        <Dropdown.Item>
          <Form.Check type="checkbox" label="Recently Added" />
        </Dropdown.Item>
        <Dropdown.Item>
          <Form.Check type="checkbox" label="A to Z" />
        </Dropdown.Item>
        <Dropdown.Item>
          <Form.Check type="checkbox" label="Old to New" />
        </Dropdown.Item>
        <Dropdown.Item>
          <Form.Check type="checkbox" label="New to Old" />
        </Dropdown.Item>
      </DropdownButton>
    </div>
    <div className="playList">
      {/* <div>Recently Added</div> */}
      <div className="VideoSuggesions_main_wrap">
        <div className="For_Videos">
          <div className="container">
            <div className="row">
              {myvideos.map((item) => (
                <div
                  key={item.id}
                  className="col-12 col-md-6 col-lg-4 col-xl-3 mb-4"
                >
                  <div className="card">
                    <div className="card-body">
                      <div className="card_img_wrap">
                        <a href={`/${rolename}/playlistView`}>
                          <img src={item.videocover} alt="" />
                        </a>
                      </div>
                      <div className="match_info_subtitles">
                        <div className="title_wrap d-flex gap-10px">
                          <div>
                            <h5>{item.title}</h5>
                            <h6>{item.profession}</h6>
                            <p className="blue-text">
                              <a href="">
                                <h6>{item.viewsonvid}</h6>
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

const AnalyticsSection = () => {
  const matchValues = 445;
  const reelsValues = 152;
  return (
    <div>
      <div className="row mt-5">
        <div className="col-3  map">
          <div className="d-flex justify-content-between align-items-center">
            <span className="mt-2 ml-2">Viewers by country</span>
            <p className="mb-0 ms-auto me-2">
              <i className="fa-regular fa-circle-question"></i>
            </p>
          </div>

          <div className="map-img d-flex">
            <GeoChart />
          </div>
        </div>
        <div className="col-3 card profileView ml-2">
          <div className="">
            <ProfileViewChart match={matchValues} reels={reelsValues} />
          </div>
        </div>
        <div className="col-3 mr-2">
          <img src={Negotiations} alt="" />
        </div>
      </div>
      <div className="row mt-5">
        <div className=" Profile-Viewers">
          <div className="all-pview">
            <div className="all-pview-text mt-3 ml-2 mb-3">Profile Viewers</div>
            <div className=" pview-header">
              <div className="col ml-4">User name</div>
              <div className="col">Profile type</div>
              <div className="col">Subscription</div>
              <div className="col mr-4 text-right">No. Views</div>
            </div>
            <div className="   pview-body">
              <div className="col">
                <img src={pv_1} alt="" className="profile-img ml-4 mt-1" />
                <span className="ml-2">Marcus</span>
              </div>
              <div className="col">
                <button className="butn1">Technical</button>
              </div>
              <div className="col">Basic</div>
              <div className="col  text-center">10</div>
            </div>
            <div className=" pview-body">
              <div className="col">
                <img src={pv_2} alt="" className="profile-img ml-4" />
                <span className="ml-2">Davis Press</span>
              </div>
              <div className="col">
                <button className=" butn2">Personal</button>
              </div>
              <div className="col">Basic</div>
              <div className="col  text-center">1</div>
            </div>
            <div className=" pview-body">
              <div className="col">
                <img src={pv_3} alt="" className="profile-img ml-4" />
                <span className="ml-2">Leo Vetrovs</span>
              </div>
              <div className="col">
                <button className=" butn2">Personal</button>
              </div>
              <div className="col">Basic</div>
              <div className="col text-center">5</div>
            </div>
            <div className=" pview-body">
              <div className="col">
                <img src={pv_4} alt="" className="profile-img ml-4" />
                <span className="ml-2">Davis Saris</span>
              </div>
              <div className="col">
                <button className="butn1">Technical</button>
              </div>
              <div className="col">Basic</div>
              <div className="col  text-center">20</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const AccessSection = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  return (
    <div>
      <div className="table_main_wrap">
        <div className="table_access_add_user d-flex align-items-center justify-content-between">
          <h6>
            Account Access{" "}
            <span>
              <img src={RoundQMark} alt="?" />
            </span>
          </h6>
          <button onClick={handleOpenModal}>+ Add User</button>
        </div>
        {isModalOpen && (
          <div className="modal">
            <div className="modal-content">
              <div className="modal_header d-flex justify-content-between align-items-center">
                <h6>Add User</h6>
                <span onClick={handleCloseModal}>
                  <img src={ModalXIcon} alt="" />
                </span>
              </div>
              <div className="modal_body mt-2">
                <div className="modal_dp text-center">
                  <img src={DummyDp} alt="" />
                </div>
                <form action="">
                  <div className="form_row">
                    <label htmlFor="">User Name</label>
                    <input type="text" placeholder="Marcus Vaccaro"  />
                  </div>
                  <div className="form_row">
                    <label htmlFor="">Mobile No</label>
                    <input type="text" placeholder="+1 5326498575" />
                  </div>
                  <div className="form_row">
                    <label htmlFor="">Email ID</label>
                    <input type="text" placeholder="abc@example.com" />
                  </div>
                  <div className="Add_btn_wrap mt-5 text-center mb-3">
                    <button>Add</button>
                  </div>
                </form>
              </div>
              {/* Your form or content here */}
            </div>
          </div>
        )}
        <div className="table-container">
          <table className="responsive-table">
            <thead>
              <tr>
                <th>User name</th>
                <th>Date Joined</th>
                <th>Mobile No.</th>
                <th>Position</th>
                <th>Email ID</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <img src={MarcusDp} alt="" /> Marcus Vaccaro
                </td>
                <td>dd-mm-yyyy</td>
                <td>Principle</td>
                <td>+1 5641239578</td>
                <td>abc@example.com</td>
                <td>
                  <img src={ActionEdit} alt="" />{" "}
                  <img src={DeleteIcon} alt="" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Profile;
