import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoutes = ({ children }) => {
  const isLoggedIn = localStorage.getItem('loginData');

  // const isLoggedIn = true;  // Simulated login state

  // If user is not logged in, navigate to /Signupin
  if (!isLoggedIn) {
    return <Navigate to="/Login" />;
  }

  // If user is logged in, render the children components
  return children;
};

export default ProtectedRoutes;
