import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { imageUploadApi } from '../api/user';

export const uploadImage = createAsyncThunk(
  'profileImage/uploadImage',
  async (params, { rejectWithValue }) => {
    try {
      const response = await imageUploadApi(params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data || { message: 'An unknown error occurred' });
    }
  }
);

const profileImageSlice = createSlice({
  name: 'profileImage',
  initialState: {
    face_picture: null,
    loading: false,
    player: {},
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(uploadImage.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(uploadImage.fulfilled, (state, action) => {
        state.loading = false;
        state.face_picture = action.payload.face_picture;
        state.player = action.payload.data?.[0];
      })
      .addCase(uploadImage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to upload profile picture';
      });
  },
});

export default profileImageSlice.reducer;
